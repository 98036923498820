import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestPupilage } from "../../redux/actions/pupilage_action";
import SectionTitle from "../../common/shared/SectionTitle";
import Loader from "../../common/loader/Loader";
import RequestCard from "./components/RequestCard";
import NoContent from "../../common/shared/NoContent";

const link = [{ name: "Pupilage Request", link: "" }];

function PupilageRequest() {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const { request } = useSelector((state) => state.pupilage);

  useEffect(() => {
    dispatch(requestPupilage()).finally(() => setLoading(false));
  }, [dispatch]);

  return (
    <div className="my-8 max-w-[1200px] m-auto px-4">
      <SectionTitle title="Pupillage Request" description="" children={link} />

      {/* {pupilageError && <AlertMessage type="error" message={pupilageError} />} */}

      {loading ? (
        <Loader />
      ) : request ? (
        request.length > 0 ? (
          <RequestCard data={request} />
        ) : (
          <NoContent message="No Pupilage Request Yet." />
        )
      ) : null}
    </div>
  );
}

export default PupilageRequest;
