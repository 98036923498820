import * as types from "../constants/glc_constants";

const initialState = {
  data: null,
  glcError: null,
  glcSuccess: null,
};

const glcReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_GLC_CHAMBER_SUCCESS:
    case types.GET_GLC_PUPIL_SUCCESS:
      return { ...state, data: payload ? payload.glc : null, glcError: null };

    case types.UPDATE_GLC_CHAMBER_SUCCESS:
    case types.DELETE_GLC_CHAMBER_SUCCESS:
    case types.UPLOAD_GLC_REPORT_CHAMBER_SUCCESS:
    case types.UPDATE_GLC_PUPIL_SUCCESS:
    case types.GLC_REPORT_REMOVAL_SUCCESS:
      return {
        ...state,
        data: payload ? payload.glc : null,
        glcSuccess: payload ? payload.message : null,
        glcError: null,
      };

    case types.GET_GLC_CHAMBER_FAILURE:
    case types.UPDATE_GLC_CHAMBER_FAILURE:
    case types.UPLOAD_GLC_REPORT_CHAMBER_FAILURE:
    case types.UPDATE_GLC_PUPIL_FAILURE:
    case types.DELETE_GLC_CHAMBER_FAILURE:
      return { ...state, glcError: payload ? payload : null };

    default:
      return state;
  }
};

export default glcReducer;
