import React, { useEffect, useState } from "react";
import { PUPILAGE_DATA } from "../../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  singlePupilage,
  updatePupilage,
} from "../../../redux/actions/pupilage_action";
import {
  Card,
  CardBody,
  Typography,
  CardFooter,
  Button,
  Spinner,
} from "@material-tailwind/react";
import InputText from "../../../common/forms/InputText";
import regions from "../../../common/shared/regions";
import DatePickerComp from "../../../common/forms/DatePicker";
import moment from "moment";
import AlertMessage from "../../../common/actions/AlertMessage";
import Loader from "../../../common/loader/Loader";
import { useNavigate } from "react-router-dom";
import SectionTitle from "../../../common/shared/SectionTitle";
import SelectOption from "../../../common/forms/SelectOption";

const link = [
  { name: "Pupillage Request", link: "/home/pupilage" },
  { name: "Edit Pupillage", link: "" },
];

function PupilageEdit() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(PUPILAGE_DATA);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, pupilageError } = useSelector((state) => state.pupilage);

  const updateFormValue = ({ updateType, value }) => {
    setFormData({ ...formData, [updateType]: value });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFormData({
      ...formData,
      [formData.p_status > 3 ? "end_date" : "start_date"]:
        moment(date).format("YYYY-MM-DD"),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const pupData = {
      id: data.pupil.id,
      s_bar_number: formData.s_bar_number,
      chamber_num: formData.chamber_num,
      p_region: formData.p_region,
      [formData.p_status > 3 ? "end_date" : "start_date"]:
        formData.p_status > 3 ? formData.end_date : formData.start_date,
    };

    dispatch(updatePupilage(pupData)).finally(() => {
      setLoading(false);
      navigate("/home/pupilage");
    });
  };

  useEffect(() => {
    setLoading(true);
    dispatch(singlePupilage()).finally(() => {
      setLoading(false);
      setFormData({
        // ...formData,
        s_bar_number: data.pupil.s_bar_number,
        chamber_num: data.pupil.chamber_num,
        start_date: data.pupil.start_date,
        end_date: data.pupil.end_date,
        p_region: data.pupil.p_region,
        p_status: data.pupil.p_status,
      });
    });
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <SectionTitle
            title="Edit Pupillage Request"
            description=""
            children={link}
          />

          <div className="my-8 max-w-[1200px] m-auto px-4">
            {pupilageError && (
              <AlertMessage type="error" message={pupilageError} />
            )}
            <Card>
              <form onSubmit={(e) => handleSubmit(e)}>
                <CardBody>
                  <Typography color="blue-gray" variant="h5">
                    Pupillage Form
                  </Typography>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 my-4">
                    <InputText
                      type="text"
                      defaultValue={formData.s_bar_number}
                      updateType="s_bar_number"
                      labelTitle="Senior Bar Number"
                      updateFormValue={updateFormValue}
                      required={true}
                    />

                    <InputText
                      type="text"
                      defaultValue={formData.chamber_num}
                      updateType="chamber_num"
                      labelTitle="Chambers / Legal Dept. Number"
                      updateFormValue={updateFormValue}
                      required={true}
                    />
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
                    <div className="mt-6">
                      <SelectOption
                        labelTtile="Select Practice Region"
                        defaultValue={data.pupil?.p_region.toString()}
                        updateFormValue={updateFormValue}
                        updateType="p_region"
                        children={regions}
                      />
                    </div>

                    <DatePickerComp
                      label={formData.p_status > 3 ? "End Date" : "Start Date"}
                      selectedDate={selectedDate}
                      maxDate={new Date()}
                      onSelectDate={handleDateChange}
                      defaultDate={
                        formData.p_status > 3
                          ? data?.pupil?.end_date
                          : data?.pupil?.start_date
                      }
                    />
                  </div>
                  <div className="mt-20">
                    <Typography variant="small" className="text-center">
                      By clicking to proceed below you affirm that the
                      information and data you have provided on this form are
                      true; and that you are not knowingly submitting
                      information which is untrue, misleading or false.
                    </Typography>
                  </div>
                </CardBody>
                <CardFooter className="flex justify-center">
                  <Button type="submit" className="flex items-center gap-3">
                    {loading && <Spinner color="white" />}
                    Save Changes
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </div>
        </>
      )}
    </>
  );
}

export default PupilageEdit;
