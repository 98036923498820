import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import React from "react";
import Cookies from "js-cookie";

function ExternalCard({ title, desc, link, icon, btnTitle, disable }) {
  const data = JSON.parse(localStorage.getItem("profile"));

  const extractDomain = () => {
    const hostnameParts = window.location.hostname.split(".");
    if (hostnameParts.length > 1) {
      return `.${hostnameParts.slice(1).join(".")}`;
    } else {
      return "";
    }
  };

  const handleSaveCookie = () => {
    const domain = extractDomain();
    const expires = new Date(Date.now() + 24 * 60 * 60 * 1000);
    Cookies.set("dataCookie", JSON.stringify(data), { domain, expires });
    window.open(link, "_blank");
  };

  return (
    <Card
      color="gray"
      className="w-full h-full flex justify-center items-center"
    >
      <CardBody className="flex flex-col pt-10 pb-6 justify-between w-full h-full items-center text-center">
        <div className="mx-auto mb-6 w-[4rem] h-[4rem]">{icon}</div>
        <Typography variant="h5" color="white">
          {title}
        </Typography>
        <Typography>{desc}</Typography>
        <Button
          onClick={handleSaveCookie}
          // onClick={() => {
          //   // window.postMessagconse(JSON.stringify(data), `${link}`);
          //   // window.open(`${link}`, "_blank");
          // }}
          className="mt-10"
          color="white"
          disabled={disable}
          fullWidth
        >
          {btnTitle}
        </Button>
      </CardBody>
    </Card>
  );
}

export default ExternalCard;
