import { Button } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function ChamberMakePayment({ data, disable = false }) {
  const navigate = useNavigate();

  const formData = { data };

  const handleNavigate = () => {
    navigate("/home/chambers/invoice", { state: { formData } });
  };

  return (
    <Button color="cyan" disabled={disable} onClick={() => handleNavigate()}>
      Make Payment
    </Button>
  );
}

export default ChamberMakePayment;
