import React from "react";
import ReceiptButton from "../../../common/buttons/ReceiptButton";
import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

function ChamberButtons({ item }) {
  const navigate = useNavigate();

  const formData = {
    year: item?.receipt?.year,
    cert_number: item.cert_number,
    name: item.name,
    exp_date: item.exp_date,
  };

  const handleCertGenerate = () => {
    navigate("/home/chamber/certificate", { state: { formData } });
  };

  return (
    <>
      <Button color="indigo" onClick={() => handleCertGenerate()}>
        View Cert
      </Button>
      <ReceiptButton
        full={false}
        id={item?.receipt?.id}
        link={`/home/chambers/receipt/${item?.receipt?.id}`}
      />
    </>
  );
}

export default ChamberButtons;
