import * as types from "../constants/bootcamp_constants";

const initialState = {
  data: null,
  bootcampError: null,
  register: null,
  message: null,
};

const bootcampReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.BOOTCAMP_REGISTRATION_SUCCESS:
      return {
        ...state,
        register: payload ? payload : null,
        bootcampError: null,
      };

    case types.BOOTCAMP_USER_SUCCESS:
    case types.BOOTCAMP_LIVE_PAYMENT_SUCCESS:
    case types.BOOTCAMP_MANUAL_PAYMENT_SUCCESS:
      return {
        ...state,
        data: payload ? payload : null,
        bootcampError: null,
      };

    case types.BOOTCAMP_UPDATE_SUCCESS:
      return {
        ...state,
        message: payload ? payload : null,
        bootcampError: null,
      };

    case types.BOOTCAMP_REGISTRATION_FAILURE:
    case types.BOOTCAMP_USER_FAILURE:
    case types.BOOTCAMP_LIVE_PAYMENT_FAILURE:
    case types.BOOTCAMP_MANUAL_PAYMENT_FAILURE:
    case types.BOOTCAMP_UPDATE_FAILURE:
      return { ...state, bootcampError: payload ? payload : null };

    default:
      return state;
  }
};

export default bootcampReducer;
