import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bootcampUserAction } from "../../redux/actions/bootcamp_action";
import SectionTitle from "../../common/shared/SectionTitle";
import OpenModal from "../../common/actions/OpenModal";
import { Typography } from "antd";
import Loader from "../../common/loader/Loader";
import NoContent from "../../common/shared/NoContent";
import BootcampList from "./components/BootcampList";

const link = [{ name: "Military Bootcamp", link: "" }];

function Bootcamp() {
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const { data, bootcampError } = useSelector((state) => state.bootcamp);
  const userData = useSelector((state) => state.auth?.userData);


  const handleModal = () => {
    setShowModal(false);
    window.location.reload();
  };

  useEffect(() => {
    dispatch(bootcampUserAction()).finally(() => {
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (bootcampError !== null) {
      setShowModal(true);
    }
  }, [bootcampError, showModal]);

  return (
    <>
      <SectionTitle
        title="Military Bootcamp"
        children={link}
        description="Note that you have to be in Good Financial Standing in order to Register for any Bootcamp."
      />

      <div className="my-8 max-w-[1200px] m-auto px-4">
        {loading ? (
          <Loader />
        ) : data.bootcamp?.length === 0 ? (
          <NoContent message="No bootcamp Available Yet." />
        ) : (
          <BootcampList row={data?.bootcamp} userData={userData} />
        )}
      </div>

      {showModal && (
        <OpenModal
          open={showModal}
          handler={handleModal}
          title="Note"
          children={<Typography color="black">{bootcampError}</Typography>}
        />
      )}
    </>
  );
}

export default Bootcamp;
