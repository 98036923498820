import * as types from "../constants/pupilage_constants";

const initialState = {
  data: null,
  pupilageError: null,
  checkData: null,
  request: null,
  manual: null,
};

const pupilageReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.ADD_PUPILAGE_SUCCESS:
    case types.SINGLE_PUPILAGE_SUCCESS:
    case types.UPDATE_PUPILAGE_SUCCESS:
    case types.VERIFY_PUPILAGE_SUCCESS:
    case types.PAYMENT_PUPILAGE_SUCCESS:
      return { ...state, data: payload ? payload : null, pupilageError: null };

    case types.ACTION_PUPILAGE_SUCCESS:
      return {
        ...state,
        manual: payload ? payload : null,
        pupilageError: null,
      };

    case types.REQUEST_PUPILAGE_SUCCESS:
      return {
        ...state,
        request: payload ? payload.pupilage : null,
        pupilageError: null,
      };

    case types.ADD_PUPILAGE_FAILURE:
    case types.CHECK_PUPILAGE_FAILURE:
    case types.SINGLE_PUPILAGE_FAILURE:
    case types.UPDATE_PUPILAGE_FAILURE:
    case types.REQUEST_PUPILAGE_FAILURE:
    case types.VERIFY_PUPILAGE_FAILURE:
    case types.ACTION_PUPILAGE_FAILURE:
    case types.PAYMENT_PUPILAGE_FAILURE:
      return { ...state, pupilageError: payload ? payload : null };

    case types.CHECK_PUPILAGE_SUCCESS:
      return {
        ...state,
        checkData: payload ? payload : null,
        pupilageError: null,
      };

    default:
      return state;
  }
};

export default pupilageReducer;
