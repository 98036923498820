import {
  CONFERENCE_BM,
  CONFERENCE_MANUAL,
  CONFERENCE_NEEDS,
  CONFERENCE_PAYMENT,
  CONFERENCE_RECEIPT,
  GET_CONFERENCE,
  REGISTER_CONFERENCE,
} from "../../constants/api_constants";
import { handleApi } from "../utils";

export const getConference = async () => {
  return handleApi("get", GET_CONFERENCE);
};

export const registerConference = async (value) => {
  return handleApi("post", REGISTER_CONFERENCE, value);
};

export const updateConference = async (value) => {
  return handleApi("post", CONFERENCE_PAYMENT, value);
};

export const manualConference = async (value) => {
  return handleApi("post", CONFERENCE_MANUAL, value);
};

export const conferenceReceipt = async (value) => {
  return handleApi("get", `${CONFERENCE_RECEIPT}/${value}`);
};

export const conferenceNeeds = async (values) => {
  return handleApi("post", CONFERENCE_NEEDS, values);
};

export const uploadFace = async (values) => {
  return handleApi("put", CONFERENCE_BM, values);
};
