import location from "../../../assets/vectors/location.svg";
import revote from "../../../assets/vectors/revote.svg";

const externalSection = [
  {
    title: "External Links",
    color: "green",
    menu: [
      {
        path: "https://voting.ghanabar.org",
        name: "Electronic Voting",
        description: "Vote here",
        btnTitle: "Vote",
        disable: false,
        icon: (
          <img
            className="mx-auto mb-6 w-[4rem] h-[4rem]"
            src={revote}
            alt="sticker"
          />
        ),
      },
      {
        path: "https://locator.ghanabar.org",
        name: "Lawyer Locator",
        description: "",
        btnTitle: "Visit Site",
        disable: false,
        icon: (
          <img
            className="mx-auto mb-6 w-[4rem] h-[4rem]"
            src={location}
            alt="sticker"
          />
        ),
      },
    ],
  },
];

export default externalSection;
