import * as types from "../constants/main_constants";

const initialState = {
  user: null,
  error: null,
  messgae: null,
};

const mainReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_USER_NAME_SUCCESS:
      return { ...state, user: payload ? payload.user : null, error: null };

    case types.UPDATE_SETTING_SUCCESS:
      return {
        ...state,
        message: payload ? payload.message : null,
        error: null,
      };

    case types.GET_USER_NAME_FAILURE:
      return { ...state, error: payload ? payload : null };

    default:
      return state;
  }
};

export default mainReducer;
