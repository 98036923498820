import { IconButton, Tooltip } from "@material-tailwind/react";
import React from "react";

function RejectIconButton({ onClick }) {
  return (
    <Tooltip content="Reject Button">
      <IconButton size="sm" className="color-red-50 my-2" onClick={onClick}>
        <svg
          height="15px"
          width="15px"
          viewBox="0 0 48 48"
          xmlns="http://www.w3.org/2000/svg"
          fill="#e51c23"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <title>decline</title>{" "}
            <g id="Layer_2" data-name="Layer 2">
              {" "}
              <g id="invisible_box" data-name="invisible box">
                {" "}
                <rect width="48" height="48" fill="none"></rect>{" "}
              </g>{" "}
              <g id="icons_Q2" data-name="icons Q2">
                {" "}
                <path d="M24,2A22,22,0,1,0,46,24,21.9,21.9,0,0,0,24,2ZM6.1,21.9a18,18,0,0,1,29.1-12L9.9,35.2A18.1,18.1,0,0,1,6.1,21.9ZM26,41.9A18.2,18.2,0,0,1,12.7,38L38,12.8a17.6,17.6,0,0,1,3.9,13.3A18.1,18.1,0,0,1,26,41.9Z"></path>{" "}
              </g>{" "}
            </g>{" "}
          </g>
        </svg>
      </IconButton>
    </Tooltip>
  );
}

export default RejectIconButton;
