import {
  Menu,
  Navbar,
  Typography,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
  Button,
} from "@material-tailwind/react";
import React, { createElement, useState } from "react";
import {
  Cog6ToothIcon,
  ChevronDownIcon,
  PowerIcon,
  LockClosedIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import logo from "../../assets/img/logo.png";
import { logout } from "../../redux/actions/auth_action";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function TopNav() {
  const user = JSON.parse(localStorage.getItem("profile")).user;

  return (
    <div className="max-h-[768px]">
      <Navbar className="sticky top-0 z-10 h-max max-w-full rounded-none px-4 py-2 lg:px-8 lg:py-4">
        <div className="flex items-center justify-between text-blue-gray-900">
          <img src={logo} alt="logo" className="h-[50px]" />

          <div className="flex items-center gap-4">
            <Typography className="hidden lg:block">
              {user.first_name} {user.last_name}
            </Typography>
            <ProfileMenu />
          </div>
        </div>
      </Navbar>
    </div>
  );
}

function ProfileMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const user = JSON.parse(localStorage.getItem("profile")).user;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigate = (link) => {
    navigate(`/home/${link}`);
  };

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove("dataCookie");
    navigate("/");
  };

  const profileMenuItems = [
    {
      label: "Edit Profile",
      icon: UserCircleIcon,
      action: () => handleNavigate("update"),
    },
    {
      label: "Change Password",
      icon: LockClosedIcon,
      action: () => handleNavigate("password"),
    },
    {
      label: "Settings",
      icon: Cog6ToothIcon,
      action: () => handleNavigate("settings"),
    },
    {
      label: "Sign Out",
      icon: PowerIcon,
      action: () => handleLogout(),
    },
  ];

  return (
    <Menu
      open={isMenuOpen}
      handler={setIsMenuOpen}
      placement="bottom-end"
      allowHover
      animate={{
        mount: { y: 0 },
        unmount: { y: 25 },
      }}
    >
      <MenuHandler>
        <Button
          variant="text"
          color="blue-gray"
          className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto"
        >
          <Avatar
            variant="circular"
            size="sm"
            alt="tania andrew"
            className="border border-gray-900 p-0.5"
            src={
              user.bm_data
                ? `https://storage-gba.s3.eu-west-1.amazonaws.com/bm/${user.bm_data}`
                : "./default.jpg"
            }
          />
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`h-3 w-3 transition-transform ${
              isMenuOpen ? "rotate-180" : ""
            }`}
          />
        </Button>
      </MenuHandler>
      <MenuList className="p-1">
        {profileMenuItems.map(({ label, icon, action }, key) => {
          const isLastItem = key === profileMenuItems.length - 1;
          return (
            <MenuItem
              key={label}
              onClick={() => action(dispatch, navigate)}
              className={`flex items-center gap-2 rounded ${
                isLastItem
                  ? "hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10"
                  : ""
              }`}
            >
              {createElement(icon, {
                className: `h-4 w-4 ${isLastItem ? "text-red-500" : ""}`,
                strokeWidth: 2,
              })}
              <Typography
                as="span"
                variant="small"
                className="font-normal"
                color={isLastItem ? "red" : "inherit"}
              >
                {label}
              </Typography>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}

export default TopNav;
