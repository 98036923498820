export const GET_USER_CHAMBER_SUCCESS = "GET_USER_CHAMBER_SUCCESS";
export const GET_USER_CHAMBER_FAILURE = "GET_USER_CHAMBER_FAILURE";

export const APPLY_CHAMBER_SUCCESS = "APPLY_CHAMBER_SUCCESS";
export const APPLY_CHAMBER_FAILURE = "APPLY_CHAMBER_FAILURE";

export const GET_CHAMBER_MEMBER_SUCCESS = "GET_CHAMBER_MEMBER_SUCCESS";
export const GET_CHAMBER_MEMBER_FAILURE = "GET_CHAMBER_MEMBER_FAILURE";

export const UPDATE_CHAMBER_SUCCESS = "UPDATE_CHAMBER_SUCCESS";
export const UPDATE_CHAMBER_FAILURE = "UPDATE_CHAMBER_FAILURE";

export const SINGLE_USER_CHAMBER_SUCCESS = "SINGLE_USER_CHAMBER_SUCCESS";
export const SINGLE_USER_CHAMBER_FAILURE = "SINGLE_USER_CHAMBER_FAILURE";

export const CHAMBER_PAYMENT_SUCCESS = "CHAMBER_PAYMENT_SUCCESS";
export const CHAMBER_PAYMENT_FAILURE = "CHAMBER_PAYMENT_FAILURE";

export const VERIFY_CHAMBER_SUCCESS = "VERIFY_CHAMBER_SUCCESS";
export const VERIFY_CHAMBER_FAILURE = "VERIFY_CHAMBER_FAILURE";

export const FAILED_CHAMBER_SUCCESS = "FAILED_CHAMBER_SUCCESS";
export const FAILED_CHAMBER_FAILURE = "FAILED_CHAMBER_FAILURE";

export const MANUAL_CHAMBER_SUCCESS = "MANUAL_CHAMBER_SUCCESS";
export const MANUAL_CHAMBER_FAILURE = "MANUAL_CHAMBER_FAILURE";

export const SECOND_CHAMBER_VERIFY_SUCCESS = "SECOND_CHAMBER_VERIFY_SUCCESS";
export const SECOND_CHAMBER_VERIFY_FAILURE = "SECOND_CHAMBER_VERIFY_FAILURE";

export const SECOND_CHAMBER_FAIL_SUCCESS = "SECOND_CHAMBER_FAIL_SUCCESS";
export const SECOND_CHAMBER_FAIL_FAILURE = "SECOND_CHAMBER_FAIL_FAILURE";

export const ADD_CHAMBER_MEMBER_SUCCESS = "ADD_CHAMBER_MEMBER_SUCCESS";
export const ADD_CHAMBER_MEMBER_FAILURE = "ADD_CHAMBER_MEMBER_FAILURE";

export const REMOVE_CHAMBER_MEMBER_SUCCESS = "REMOVE_CHAMBER_MEMBER_SUCCESS";
export const REMOVE_CHAMBER_MEMBER_FAILURE = "REMOVE_CHAMBER_MEMBER_FAILURE";

export const UPLOAD_CHAMBER_RGD_SUCCESS = "UPLOAD_CHAMBER_RGD_SUCCESS";
export const UPLOAD_CHAMBER_RGD_FAILURE = "UPLOAD_CHAMBER_RGD_FAILURE";

export const UPLOAD_CHAMBER_VAT_SUCCESS = "UPLOAD_CHAMBER_VAT_SUCCESS";
export const UPLOAD_CHAMBER_VAT_FAILURE = "UPLOAD_CHAMBER_VAT_FAILURE";

export const UPLOAD_CHAMBER_SSNIT_SUCCESS = "UPLOAD_CHAMBER_SSNIT_SUCCESS";
export const UPLOAD_CHAMBER_SSNIT_FAILURE = "UPLOAD_CHAMBER_SSNIT_FAILURE";

export const UPLOAD_CHAMBER_EVIDENCE_SUCCESS =
  "UPLOAD_CHAMBER_EVIDENCE_SUCCESS";
export const UPLOAD_CHAMBER_EVIDENCE_FAILURE =
  "UPLOAD_CHAMBER_EVIDENCE_FAILURE";

export const REMOVE_CHAMBER_FILE_SUCCESS = "REMOVE_CHAMBER_FILE_SUCCESS";
export const REMOVE_CHAMBER_FILE_FAILURE = "REMOVE_CHAMBER_FILE_FAILURE";
