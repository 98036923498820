import {
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import React from "react";
import DuesStatus from "./DuesStatus";
import DuesPayButton from "./DuesPayButton";
import RegionalStatus from "./RegionalStatus";

function DuesList({ data }) {
  const isPaid = data[0].receipts.find(
    (item) => item.post_type === "dues" && item.status === 1
  );

  const userData = JSON.parse(localStorage.getItem("profile"));

  if (isPaid && isPaid.amount === parseInt(data[0].amount)) {
    userData.user.standing = true;
    localStorage.setItem("profile", JSON.stringify(userData));
  }

  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-6 mt-10">
      {data &&
        data.map((item, index) => (
          <div key={index} className="card-wrapper">
            <Card>
              <CardBody>
                <Typography variant="h5" color="blue-gray" className="mb-4">
                  {item.name}
                </Typography>
                <div className="flex my-4">
                  <Typography className="font-semibold" variant="paragraph">
                    National Dues:
                  </Typography>
                  <DuesStatus data={item} title="dues" />
                </div>
                <div className="flex">
                  <Typography className="font-semibold" variant="paragraph">
                    Regional Dues:
                  </Typography>
                  <RegionalStatus data={item} title="regdues" />
                </div>
              </CardBody>
              <CardFooter>
                <DuesPayButton data={item} />
              </CardFooter>
            </Card>
          </div>
        ))}
    </div>
  );
}

export default DuesList;
