import createAsyncAction from "../../utils/asyncAction";
import * as api from "../api/glc/glc_api";
import * as types from "../constants/glc_constants";

const getGlcChamberTypes = {
  success: types.GET_GLC_CHAMBER_SUCCESS,
  failure: types.GET_GLC_CHAMBER_FAILURE,
};

const updateGlcChamberTypes = {
  success: types.UPDATE_GLC_CHAMBER_SUCCESS,
  failure: types.UPDATE_GLC_CHAMBER_FAILURE,
};

const deleteGlcChamberTypes = {
  success: types.DELETE_GLC_CHAMBER_SUCCESS,
  failure: types.DELETE_GLC_CHAMBER_FAILURE,
};

const getGlcPupilTypes = {
  success: types.GET_GLC_PUPIL_SUCCESS,
  failure: types.GET_GLC_PUPIL_FAILURE,
};

const updateGlcPupilTypes = {
  success: types.UPDATE_GLC_PUPIL_SUCCESS,
  failure: types.UPDATE_GLC_PUPIL_FAILURE,
};

const glcReportRemovalTypes = {
  success: types.GLC_REPORT_REMOVAL_SUCCESS,
  failure: types.GLC_REPORT_REMOVAL_FAILURE,
};

export const getGlcChamber = createAsyncAction(
  api.getGLCChamber,
  getGlcChamberTypes
);

export const updateGlcChamber = createAsyncAction(
  api.updateGLCChamber,
  updateGlcChamberTypes
);

export const deleteGlcChamber = createAsyncAction(
  api.deleteGLCChamber,
  deleteGlcChamberTypes
);

export const getGlcPupil = createAsyncAction(api.getGLCPupil, getGlcPupilTypes);

export const updateGlcPupil = createAsyncAction(
  api.updateGLCPupil,
  updateGlcPupilTypes
);

export const glcReportRemoval = createAsyncAction(
  api.glcReportRemoval,
  glcReportRemovalTypes
);

export const uploadGLCReport = (id, formData) => async (dispatch) => {
  try {
    const response = await api.uploadGLCReportChamber(id, formData);
    const { error, data } = response;

    if (error) {
      dispatch({
        type: types.UPLOAD_GLC_REPORT_CHAMBER_FAILURE,
        payload: error,
      });
    } else {
      dispatch({
        type: types.UPLOAD_GLC_REPORT_CHAMBER_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: types.UPLOAD_GLC_REPORT_CHAMBER_FAILURE,
      payload: error.message,
    });
  }
};
