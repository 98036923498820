import {
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import React from "react";
import LicenseStatus from "./LicenseStatus";
import LicenseButtons from "../common/LicenseButtons";

function LicenseList({ data }) {
  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-6 mt-10">
      {data &&
        data.map((item) => (
          <div key={item.id} className="card-wrapper">
            <Card>
              <CardBody>
                <Typography variant="h5" color="black" className="mb-4">
                  {item.name}
                </Typography>
                <Typography variant="paragraph" className="font-semibold">
                  Amount: GH¢{item.amount.toFixed(2)}
                </Typography>
                <div className="flex">
                  <Typography className="font-semibold" variant="paragraph">
                    Status:
                  </Typography>
                  <LicenseStatus
                    status={item.receipts}
                    manual={item.manualGreen}
                  />
                </div>
              </CardBody>
              <CardFooter>
                <LicenseButtons data={item} />
              </CardFooter>
            </Card>
          </div>
        ))}
    </div>
  );
}

export default LicenseList;
