import React, { useEffect, useMemo, useRef, useState } from "react";
//import { createCanvas } from "canvas";
import { Months } from "../../../utils/months";
import SectionTitle from "../../../common/shared/SectionTitle";
import { Button } from "@material-tailwind/react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import Loader from "../../../common/loader/Loader";

function LicenseCertificate() {
    const [certificateImage, setCertificateImage] = useState(null);

    const location = useLocation();

    let componentRef = useRef();
    const canvasRef = useRef();

    const user = useSelector((state) => state.auth?.userData);

    const certData = location.state?.formData;

    const formData = useMemo(
        () => ({
            year: certData?.year,
            cert_number: certData?.cert_number,
            name: `${user.title} ${user.first_name} ${user.last_name}`,
            date_paid: certData?.date_paid,
        }),
        [certData, user]
    );

    useEffect(() => {
        const generateCertificate = async () => {
            try {
                const image = new Image();
                image.src = `/cert/license/license${formData.year}.jpg`;

                // Wait for the image to load
                image.onload = async () => {
                    await image.decode();

                    const canvas = canvasRef.current;
                    if (!canvas) {
                        console.error("Canvas not found");
                        return;
                    }
                    const ctx = canvas.getContext("2d");
                    if (!ctx) {
                        console.error("Context not found");
                        return;
                    }

                    canvas.width = image.width;
                    canvas.height = image.height;

                    ctx.drawImage(image, 0, 0);
                    ctx.font = "bold 25px Arial";
                    ctx.fillText(formData.cert_number, 680, 137);

                    const nameText = formData.name;
                    const textWidth = ctx.measureText(nameText).width;
                    const xCoordinate = (canvas.width - textWidth) / 2;
                    ctx.fillText(nameText.toUpperCase(), xCoordinate, 635);

                    const date = new Date(formData.date_paid);
                    const day = date.getDate();
                    const month = Months[date.getMonth()];
                    const yearDate = date.getFullYear();

                    const twoDate = formData.year.toString().slice(-2);
                    ctx.fillText(twoDate, 660, 705);

                    ctx.fillText(day, 250, 820);
                    ctx.fillText(month, 500, 820);
                    ctx.fillText(yearDate % 100, 770, 825);

                    const dataUrl = canvas.toDataURL("image/png");
                    setCertificateImage(dataUrl);
                };

                image.onerror = (err) => {
                    console.error("Image loading error:", err);
                };
            } catch (error) {
                console.error("Error generating certificate:", error);
            }
        };

        generateCertificate();
    }, [formData]);

    return (
        <>
            <SectionTitle title="License Certificate" description="" children={[]} />

            <div className="my-8 max-w-[1200px] m-auto px-4 flex flex-col items-center justify-center">
                <div className="flex items-center gap-3 mb-8">
                    <div>
                        <ReactToPrint
                            trigger={() => <Button>Print Cert</Button>}
                            content={() => componentRef}
                            removeAfterPrint={false}
                        />
                    </div>
                </div>

                <canvas ref={canvasRef} className=" hidden" />

                {!certificateImage ? (
                    <Loader />
                ) : (
                    <div ref={(el) => (componentRef = el)}>
                        <img src={certificateImage} alt="Generated Certificate" />
                    </div>
                )}
            </div>
        </>
    );
}

export default LicenseCertificate;
