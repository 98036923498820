import {
  GET_DUES_RECEIPT,
  GET_RECEIPT,
  GET_USER_RECEIPT,
} from "../../constants/api_constants";
import { handleApi } from "../utils";

export const getReceipt = async (value) => {
  return handleApi("get", `${GET_RECEIPT}/${value}`);
};

export const getDuesReceipt = async (value) => {
  return handleApi("get", `${GET_DUES_RECEIPT}/${value}`);
};

export const getUserReceipt = async () => {
  return handleApi("get", GET_USER_RECEIPT);
};
