import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { upoloadConferenceFace } from "../../redux/actions/conference_action";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Typography,
} from "@material-tailwind/react";
import Loader from "../loader/Loader";

function ImageUpload({ open }) {
  const [loading, setLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const dispatch = useDispatch();

  const handleClose = () => {
    window.location.reload();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLoading(true);

      setSelectedFile(file);
      setFileName(file.name);

      let formData = new FormData();
      formData.append("file", file);
      dispatch(upoloadConferenceFace(formData))
        .then((result) => {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Dialog open={open} size="sm">
      <DialogHeader>Image Upload</DialogHeader>
      <DialogBody>
        <div className="relative h-50 max-w-lg bg-white bg-gray-100 rounded-lg shadow-inner">
          <input
            type="file"
            id="file-upload"
            className="hidden"
            onChange={handleFileChange}
          />
          <label
            htmlFor="file-upload"
            className="z-20 flex flex-col-reverse items-center justify-center w-full h-full cursor-pointer"
          >
            {loading ? (
              <Loader />
            ) : (
              <>
                {selectedFile ? (
                  <Typography
                    variant="pharagraph"
                    className="text-center"
                    color="black"
                  >
                    Image successfully uploaded.
                  </Typography>
                ) : (
                  <>
                    <Typography variant="pharagraph" color="black">
                      <span className="font-bold">Quality: </span>
                      Shows all facial features with a clear background. Recent
                      picture, not older than 6 months
                    </Typography>

                    <Typography
                      variant="pharagraph mb-2 text-left"
                      color="black"
                    >
                      <span className="font-bold">Purpose: </span>
                      Automatic facial recognition for admission at GBA events
                    </Typography>

                    <Typography
                      variant="pharagraph"
                      className="text-center mb-4"
                      color="black"
                    >
                      Please click here to upload your passport picture
                    </Typography>
                  </>
                )}

                {selectedFile ? (
                  <svg
                    width="100px"
                    height="100px"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M36.03 25.291C35.4996 25.291 34.9909 25.0803 34.6158 24.7052C34.2407 24.3301 34.03 23.8214 34.03 23.291V6.271H16C14.4087 6.271 12.8826 6.90313 11.7574 8.02835C10.6321 9.15357 10 10.6797 10 12.271V52.351C10 53.9423 10.6321 55.4684 11.7574 56.5936C12.8826 57.7188 14.4087 58.351 16 58.351H47.06C48.6504 58.3481 50.1748 57.715 51.2994 56.5904C52.424 55.4658 53.0571 53.9414 53.06 52.351V25.291H36.03Z"
                        fill="#999999"
                      ></path>{" "}
                      <path
                        d="M51.88 21.291L38.03 7.44092V8.27094V21.291H51.06H51.88Z"
                        fill="#000000"
                      ></path>{" "}
                    </g>
                  </svg>
                ) : (
                  <svg
                    className="z-10 w-20 h-20 text-indigo-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    alt="Upload File Icon"
                  >
                    <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"></path>
                  </svg>
                )}
              </>
            )}
          </label>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button variant="text" color="red" onClick={handleClose}>
          <span>Close</span>
        </Button>
      </DialogFooter>
    </Dialog>

    //     <Modal
    //       title="Image Upload"
    //       setOpen={open}
    //       handleClose={handleClose}
    //       loading={loading}
    //       size="sm"
    //       children={
    //         <>
    //           <div className="relative h-50 max-w-lg bg-white bg-gray-100 rounded-lg shadow-inner">
    //             <input
    //               type="file"
    //               id="file-upload"
    //               className="hidden"
    //               onChange={handleFileChange}
    //             />
    //             <label
    //               htmlFor="file-upload"
    //               className="z-20 flex flex-col-reverse items-center justify-center w-full h-full cursor-pointer"
    //             >
    //               <p className="z-10 text-md font-light text-center text-black">
    //                 {selectedFile
    //                   ? "Image successfully uploaded."
    //                   : "Click here to upload image for facial recognition."}
    //               </p>
    //               {selectedFile ? (
    //                 <svg
    //                   width="100px"
    //                   height="100px"
    //                   viewBox="0 0 64 64"
    //                   fill="none"
    //                   xmlns="http://www.w3.org/2000/svg"
    //                 >
    //                   <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    //                   <g
    //                     id="SVGRepo_tracerCarrier"
    //                     strokeLinecap="round"
    //                     strokeLinejoin="round"
    //                   ></g>
    //                   <g id="SVGRepo_iconCarrier">
    //                     {" "}
    //                     <path
    //                       d="M36.03 25.291C35.4996 25.291 34.9909 25.0803 34.6158 24.7052C34.2407 24.3301 34.03 23.8214 34.03 23.291V6.271H16C14.4087 6.271 12.8826 6.90313 11.7574 8.02835C10.6321 9.15357 10 10.6797 10 12.271V52.351C10 53.9423 10.6321 55.4684 11.7574 56.5936C12.8826 57.7188 14.4087 58.351 16 58.351H47.06C48.6504 58.3481 50.1748 57.715 51.2994 56.5904C52.424 55.4658 53.0571 53.9414 53.06 52.351V25.291H36.03Z"
    //                       fill="#999999"
    //                     ></path>{" "}
    //                     <path
    //                       d="M51.88 21.291L38.03 7.44092V8.27094V21.291H51.06H51.88Z"
    //                       fill="#000000"
    //                     ></path>{" "}
    //                   </g>
    //                 </svg>
    //               ) : (
    //                 <svg
    //                   className="z-10 w-8 h-8 text-indigo-400"
    //                   fill="currentColor"
    //                   viewBox="0 0 20 20"
    //                   xmlns="http://www.w3.org/2000/svg"
    //                   alt="Upload File Icon"
    //                 >
    //                   <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"></path>
    //                 </svg>
    //               )}
    //             </label>
    //           </div>
    //         </>
    //       }
    //     />
  );
}

export default ImageUpload;
