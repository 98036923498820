import { GET_USER_NAME, UPDATE_SETTING } from "../constants/api_constants";
import { handleApi } from "./utils";

export const getUserNameApi = async (value) => {
  return handleApi("get", `${GET_USER_NAME}/${value}`);
};

export const updateSettingsApi = async (values) => {
  return handleApi("put", UPDATE_SETTING, values);
};
