export const GET_GLC_CHAMBER_SUCCESS = "GET_GLC_CHAMBER_SUCCESS";
export const GET_GLC_CHAMBER_FAILURE = "GET_GLC_CHAMBER_FAILURE";

export const UPDATE_GLC_CHAMBER_SUCCESS = "UPDATE_GLC_CHAMBER_SUCCESS";
export const UPDATE_GLC_CHAMBER_FAILURE = "UPDATE_GLC_CHAMBER_FAILURE";

export const DELETE_GLC_CHAMBER_SUCCESS = "DELETE_GLC_CHAMBER_SUCCESS";
export const DELETE_GLC_CHAMBER_FAILURE = "DELETE_GLC_CHAMBER_FAILURE";

export const UPLOAD_GLC_REPORT_CHAMBER_SUCCESS =
  "UPLOAD_GLC_REPORT_CHAMBER_SUCCESS";
export const UPLOAD_GLC_REPORT_CHAMBER_FAILURE =
  "UPLOAD_GLC_REPORT_CHAMBER_FAILURE";

export const GET_GLC_PUPIL_SUCCESS = "GET_GLC_PUPIL_SUCCESS";
export const GET_GLC_PUPIL_FAILURE = "GET_GLC_PUPIL_FAILURE";

export const UPDATE_GLC_PUPIL_SUCCESS = "UPDATE_GLC_PUPIL_SUCCESS";
export const UPDATE_GLC_PUPIL_FAILURE = "UPDATE_GLC_PUPIL_FAILURE";

export const GLC_REPORT_REMOVAL_SUCCESS = "GLC_REPORT_REMOVAL_SUCCESS";
export const GLC_REPORT_REMOVAL_FAILURE = "GLC_REPORT_REMOVAL_FAILURE";
