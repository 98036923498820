import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CHAMBER_MEMBER_DATA } from "../../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  addChamberMember,
  getChamberMember,
  removeChamberMember,
} from "../../../redux/actions/chamber_actions";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import Loader from "../../../common/loader/Loader";
import DeleteIconButton from "../../../common/buttons/DeleteIconButton";
import OpenModal from "../../../common/actions/OpenModal";
import SelectOption from "../../../common/forms/SelectOption";
import DatePickerComp from "../../../common/forms/DatePicker";
import InputCheck from "../../../common/forms/InputCheck";

const memberType = [
  { id: "member", name: "Member" },
  { id: "junior", name: "Pupil" },
];

function ChamberMembers() {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [addLoading, setAddLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [formData, setFormData] = useState(CHAMBER_MEMBER_DATA);
  const [addNewModal, setAddNewModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const dispatch = useDispatch();

  const { members } = useSelector((state) => state.chamber);

  const updateFormValue = ({ updateType, value }) => {
    setFormData({ ...formData, [updateType]: value });
  };

  const openAddModal = () => setAddNewModal(!addNewModal);
  const openConfirmModal = (params) => {
    setConfirmModal(!confirmModal);
    setFormData(params);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.bar_number || !formData.status) {
      return;
    }

    setAddLoading(true);
    const form = {
      id: id,
      bar_number: formData.bar_number,
      status: formData.status,
    };
    dispatch(addChamberMember(form)).finally(() => {
      setAddLoading(false);
      setAddNewModal(false);
      setFormData(CHAMBER_MEMBER_DATA);
    });
  };

  const handleRemoval = (id) => {
    setConfirmLoading(true);
    dispatch(removeChamberMember(formData.id)).finally(() => {
      setConfirmLoading(false);
      setConfirmModal(false);
      window.location.reload();
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFormData({ ...formData, date_added: date.toISOString() });
  };

  useEffect(() => {
    dispatch(getChamberMember(id)).finally(() => setLoading(false));
  }, [dispatch, id]);

  return (
    <Card>
      <CardBody>
        <div className="mb-8">
          <Typography color="blue-gray" variant="h5">
            Chamber Members
          </Typography>
          <hr />
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-10 my-4">
            <div>
              <Typography color="black" variant="h5">
                Members
              </Typography>
              {members
                ?.filter((item) => item.status === "member")
                .map((item) => (
                  <div
                    key={item.id}
                    className="flex items-center justify-between my-4"
                  >
                    <Typography color="black" variant="paragraph">
                      {item.title +
                        " " +
                        item.first_name +
                        " " +
                        item.last_name +
                        " - " +
                        item.bar_number}
                    </Typography>

                    {
                      <DeleteIconButton
                        onClick={() => openConfirmModal(item)}
                      />
                    }
                  </div>
                ))}
            </div>

            <div>
              <Typography color="black" varian1t="h5">
                Pupils
              </Typography>
              {members
                ?.filter((item) => item.status === "junior")
                .map((item) => (
                  <div
                    key={item.id}
                    className="flex items-center justify-between my-4"
                  >
                    <Typography color="black" variant="paragraph">
                      {item.title +
                        " " +
                        item.first_name +
                        " " +
                        item.last_name +
                        " - " +
                        item.bar_number}
                    </Typography>

                    <DeleteIconButton onClick={() => openConfirmModal(item)} />
                  </div>
                ))}
            </div>
          </div>
        )}
      </CardBody>

      <CardFooter className="flex justify-center">
        <Button
          type="button"
          className="flex item-center gap-3"
          onClick={openAddModal}
        >
          Add Member
        </Button>
      </CardFooter>

      {addNewModal && (
        <OpenModal
          open={addNewModal}
          handler={openAddModal}
          title="Add New"
          loading={addLoading}
          actionTitle="Add New"
          handleSubmit={(e) => handleSubmit(e)}
          children={
            <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-4 my-4">
              <InputCheck
                type="text"
                defaultValue={formData.bar_number}
                updateType="bar_number"
                labelTitle="Bar Number"
                containerStyle="mt-6"
                updateFormValue={updateFormValue}
                required
              />

              <div className="mt-6">
                <SelectOption
                  labelTtile="Select Status"
                  defaultValue={formData.status}
                  updateFormValue={updateFormValue}
                  updateType="status"
                  children={memberType}
                />
              </div>

              <DatePickerComp
                label="Date Added"
                selectedDate={selectedDate}
                onSelectDate={handleDateChange}
                maxDate={new Date()}
              />
            </div>
          }
        />
      )}

      {confirmModal && (
        <OpenModal
          open={confirmModal}
          handler={openConfirmModal}
          title="Confirm Deletion"
          loading={confirmLoading}
          actionTitle="Remove"
          handleSubmit={(e) => handleRemoval(e)}
          children={
            <Typography color="black" variant="paragraph">
              Please confirm you want to remove{" "}
              <strong>
                {" "}
                {formData.title +
                  " " +
                  formData.first_name +
                  " " +
                  formData.last_name +
                  " - " +
                  formData.bar_number}
              </strong>{" "}
              from this chamber?
            </Typography>
          }
        />
      )}
    </Card>
  );
}

export default ChamberMembers;
