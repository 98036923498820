import React, { useEffect, useState } from "react";
import SectionTitle from "../../common/shared/SectionTitle";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserReceipt } from "../../redux/actions/receipt_action";
import AlertMessage from "../../common/actions/AlertMessage";
import Loader from "../../common/loader/Loader";
import NoContent from "../../common/shared/NoContent";
import ReceiptList from "./ReceiptList";

const link = [{ name: "Solicitors License", link: "" }];

function AllReceipts() {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const { data, receiptError } = useSelector((state) => state.payment);

  useEffect(() => {
    dispatch(getAllUserReceipt()).finally(() => setLoading(false));
  }, [dispatch]);

  return (
    <>
      <SectionTitle
        title="All Receipts"
        description="View all receipts"
        children={link}
        link={"/home"}
      />

      <div className="my-8 max-w-[1200px] m-auto px-4">
        {receiptError && <AlertMessage type="error" message={receiptError} />}

        {loading ? (
          <Loader />
        ) : data.receipt.length === 0 ? (
          <NoContent message="No Receipts Yet." />
        ) : (
          <ReceiptList data={data?.receipt} />
        )}
      </div>
    </>
  );
}

export default AllReceipts;
