import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import React, { useRef, useState } from "react";
import ConferenceStatusTag from "../components/ConferenceStatusTag";
import ConferenceRegister from "./ConferenceRegister";
import ConferencePayment from "./ConferencePayment";
import DetailItem from "../../../common/shared/DetailItem";
import ConferenceReceiptButton from "../common/ConferenceReceiptButton";
import HotelButton from "../common/HotelButton";
import moment from "moment";
import OpenModal from "../../../common/actions/OpenModal";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import InputArea from "../../../common/forms/InputArea";
import { conferenceNeeds } from "../../../redux/actions/conference_action";
import ReactToPrint from "react-to-print";
import ImageUpload from "../../../common/forms/ImageUpload";

function ConferenceList({ row }) {
  const [code, setQrcode] = useState(false);
  const [needs, setNeeds] = useState(false);
  const [showUpload, setShowUpload] = useState(false);

  const [loading, setLoading] = useState(false);

  let cardBodyRef = useRef();

  const userData = useSelector((state) => state.auth?.userData);

  const [formData, setFormData] = useState(row);

  const dispatch = useDispatch();

  const updateFormValue = ({ updateType, value }) => {
    setFormData({ ...formData, [updateType]: value });
  };

  const handleCode = () => setQrcode(!code);
  const handleNeeds = () => setNeeds(!needs);

  const handleNeedsForm = async () => {
    setLoading(true);

    const sendData = {
      conference: formData[0].id,
      foods: formData.foods,
      needs: formData.needs,
      bar_number: userData.bar_number,
    };

    await dispatch(conferenceNeeds(sendData)).finally(() => {
      setLoading(false);
      setNeeds(!needs);
      window.location.reload();
    });
  };

  const handleUploadModal = () => {
    setShowUpload(!showUpload);
  };

  return (
    row &&
    row.map((data) => (
      <div key={data.id} className="card-wrapper">
        {data.receiptStatus === false &&
          Date.parse(data.meta.pause) > Date.now() && (
            <ConferenceRegister item={data} disabled={!data.standing} />
          )}
        <Card>
          <CardBody>
            <ConferenceStatusTag data={data} />
            {data.bm == null && Date.parse(data.meta.pause) > Date.now() && (
              <Button
                size="lg"
                color="pink"
                className="my-8"
                onClick={handleUploadModal}
              >
                CLICK HERE TO UPLOAD IMAGE FOR FACE RECOGNITION ACCESS DURING
                CONFERENCE
              </Button>
            )}
            <Typography variant="h5" color="blue-gray" className="mb-4">
              {`${data.name} ${data.conf_type ? " - " + data.conf_type : ""}`}
            </Typography>

            {data.meta.theme && (
              <DetailItem title="Theme" item={data.meta.theme} />
            )}

            {data.meta.speaker && (
              <DetailItem title="Keynote Speaker" item={data.meta.speaker} />
            )}

            {data.meta.venue && (
              <DetailItem
                title="Venue"
                item={
                  <>
                    MORNING: {data.meta.venue} <br /> EVENING:{" "}
                    {data.meta.evening}
                  </>
                }
              />
            )}

            <DetailItem
              title="Date"
              item={`${moment(data?.start_date).format(
                "dddd MMMM Do YYYY"
              )} to ${moment(data?.end_date).format("dddd MMMM Do YYYY")}`}
            />

            {!data.receiptStatus && !data.exempt && (
              <div>
                <DetailItem
                  title="Amount"
                  item={`GH¢${data.amount.toFixed(2)}`}
                />
              </div>
            )}
          </CardBody>
          <CardFooter>
            {data.receipt ? (
              <div className="flex flex-col gap-4 md:flex-row md:items-center">
                <ConferenceReceiptButton
                  id={data.receipt.id}
                  link={`/home/conference/receipt/${data.receipt.id}`}
                  disabled={data.receipt.amount === 0 ? true : false}
                />
                {data.conf_type === "In-Person" && (
                  <>
                    {data.meta.hotels && (
                      <HotelButton link={data.meta.hotels} />
                    )}

                    <Button onClick={handleCode} color="amber">
                      Generate Access Code
                    </Button>

                    <Button onClick={handleNeeds} color="purple">
                      Special Needs / Special Foods
                    </Button>
                  </>
                )}
              </div>
            ) : data.receiptStatus && data.amount > 0 ? (
              <ConferencePayment
                data={data}
                disable={Date.parse(data.meta.pause) < Date.now()}
              />
            ) : null}
          </CardFooter>
        </Card>

        {code && (
          <OpenModal
            open={code}
            handler={handleCode}
            // title="Conference Code"
            children={
              <>
                <div class="flex justify-center items-center mb-6">
                  <div class="text-center">
                    <ReactToPrint
                      trigger={() => <Button>Print Code</Button>}
                      content={() => cardBodyRef}
                      removeAfterPrint={false}
                    />
                  </div>
                </div>

                <div
                  ref={(el) => (cardBodyRef = el)}
                  style={{
                    height: "auto",
                    margin: "0 auto",
                    maxWidth: 200,
                    width: "100%",
                  }}
                >
                  <QRCode
                    size={256}
                    className="mt-12"
                    style={{
                      height: "auto",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                    value={userData.bar_number}
                    // viewBox={`0 0 256 256`}
                  />
                </div>
              </>
            }
          />
        )}

        {needs && (
          <OpenModal
            open={needs}
            handler={handleNeeds}
            title="Conference Needs"
            actionTitle="Submit"
            handleSubmit={handleNeedsForm}
            loading={loading}
            children={
              <>
                <div className="mb-6">
                  <InputArea
                    defaultValue={data?.needs}
                    updateType="needs"
                    labelTitle="Special Needs"
                    updateFormValue={updateFormValue}
                  />
                </div>
                <InputArea
                  defaultValue={data?.foods}
                  updateType="foods"
                  labelTitle="Food Exemptions"
                  updateFormValue={updateFormValue}
                />
              </>
            }
          />
        )}

        {showUpload && (
          <ImageUpload open={showUpload} handleClose={handleUploadModal} />
          // <OpenModal
          //   open={showUpload}
          //   handler={handleUploadModal}
          //   title="Upload Image"
          //   actionTitle="Submit"
          //   children={
          //     <>
          //       <FileUpload
          //         label="Image"
          //         onUpload={(file) =>
          //           dispatch(upoloadConferenceFace(file, "file"))
          //         }
          //       />
          //     </>
          //   }
          // />
        )}
      </div>
    ))

    // </div>
  );
}

export default ConferenceList;
