import React, { useMemo } from "react";
import Divider from "../../../common/shared/Divider";
import checkYear from "../../../utils/yearCheck";
import regions from "../../../common/shared/regions";
import { normalDues } from "../../../redux/actions/dues_action";
import ReceiptButton from "../../../common/buttons/ReceiptButton";
import DuesPayment from "./DuesPayment";
import ManualPaymentButton from "./ManualPaymentButton";
import { useLocation } from "react-router-dom";
import SectionTitle from "../../../common/shared/SectionTitle";
import DuesMakePaymentButton from "../common/DuesMakePaymentButton";

function DuesPaymentPage() {
    const location = useLocation();

    const duesData = location.state?.formData;

    const data = useMemo(
        () => ({
            data: duesData.data,
        }),
        [duesData]
    );

    const userData = JSON.parse(localStorage.getItem("profile")).user;

    let receiptCheck = 0;
    let getReceipt;

    getReceipt = duesData?.data?.receipts?.find((item) => item.post_type === "dues");

    const dataAmount = Number(duesData?.data?.amount);
    const receiptAmount = Number(getReceipt?.amount);

    if (!isNaN(dataAmount) && !isNaN(receiptAmount) && dataAmount > receiptAmount) {
        receiptCheck = dataAmount - receiptAmount;
    }

    const combineRegion = regions.map((region) => ({
        ...region,
        amount: data.data.meta.regional[region.id],
    }));

    const userYears = useMemo(() => checkYear(userData.date_of_call), [userData.date_of_call]);

    console.log("----------- data ----------");
    console.log(data);

    return (
        <>
            <SectionTitle
                title="Dues Payment Page"
                description="Pay national dues and any regional dues of your choice"
                children={[]}
                link={"/home/dues"}
            />

            <div className="my-8 max-w-[1200px] m-auto px-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
                    <div>
                        <Divider title="National Dues" variant="h5" />
                        <div className="flex justify-between">
                            <div className="flex-1">National Dues </div>
                            <div className="flex-1 ml-4">
                                <div className="flex justify-between">
                                    <div className="flex-1">
                                        {receiptCheck > 0
                                            ? `${parseInt(data?.data?.amount) - receiptCheck}`
                                            : `GH¢${parseInt(data?.data?.amount)}`}
                                    </div>

                                    <div className="flex-1 ml-4">
                                        {data?.data?.receipts?.some(
                                            (item) => item.post_type === "dues" && item.status === 1
                                        ) ? (
                                            <div className="flex w-max gap-4">
                                                <ReceiptButton
                                                    id={
                                                        data?.data?.receipts?.find(
                                                            (item) => item.post_type === "dues"
                                                        )?.id || null
                                                    }
                                                    link={`/home/dues/receipt/${
                                                        data?.data?.receipts?.find(
                                                            (item) => item.post_type === "dues"
                                                        )?.id || null
                                                    }`}
                                                />
                                            </div>
                                        ) : (
                                            <div className="flex w-max gap-4">
                                                <DuesMakePaymentButton
                                                    data={data}
                                                    title={{ title: "dues" }}
                                                />
                                            </div>
                                            // <div className="flex w-max gap-4">
                                            //   <DuesPayment
                                            //     data={data}
                                            //     amount={data?.data?.amount}
                                            //     year={data?.data?.year}
                                            //     id={data?.data?.id}
                                            //     post_type="dues"
                                            //   />
                                            //   <ManualPaymentButton
                                            //     amount={data?.data?.amount}
                                            //     year={data?.data?.year}
                                            //     id={data?.data?.id}
                                            //     post_type="dues"
                                            //     apiFxn={normalDues}
                                            //   />
                                            // </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {receiptCheck > 0 && data.data.year === "2023" && (
                            <div className="flex justify-between my-4">
                                <div className="flex-1 text-red-500">OutStanding Payment </div>
                                <div className="flex-1 ml-4">
                                    <div className="flex justify-between">
                                        <div className="flex-1 text-red-500">GH¢{receiptCheck}</div>
                                        <div className="flex-1 ml-4">
                                            <div className="flex-1 ml-4">
                                                <div className="flex w-max gap-4">
                                                    <DuesMakePaymentButton
                                                        data={{ ...data, amount: receiptCheck }}
                                                        title={{ title: "outstanding" }}
                                                    />
                                                    {/* <UpdatePaymentButton
                            data={data?.data}
                            amount={data?.data?.amount}
                            id={data?.data?.id}
                          /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <Divider title="Regional Dues" variant="h5" />

                        <div className="my-4">
                            {combineRegion.map((region, index) => (
                                <div key={index} className="flex justify-between my-4">
                                    <div className="flex-1">{region.name} Region</div>
                                    <div className="flex-1 ml-4">
                                        <div className="flex justify-between">
                                            <div className="flex-1">
                                                GH¢{parseInt(region?.amount)}
                                            </div>
                                            <div className="flex-1 ml-4">
                                                {data?.data?.receipts?.some(
                                                    (item) =>
                                                        item.post_type === "regdues" &&
                                                        item.status === 1 &&
                                                        item.sub_type === region.name
                                                ) ? (
                                                    <div className="flex w-max gap-4">
                                                        <ReceiptButton
                                                            id={
                                                                data?.data?.receipts?.find(
                                                                    (item) =>
                                                                        item.post_type ===
                                                                            "regdues" &&
                                                                        item.sub_type ===
                                                                            region.name
                                                                )?.id || null
                                                            }
                                                            link={`/home/dues/receipt/${
                                                                data?.data?.receipts?.find(
                                                                    (item) =>
                                                                        item.post_type ===
                                                                            "regdues" &&
                                                                        item.sub_type ===
                                                                            region.name
                                                                )?.id || null
                                                            }`}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="flex w-max gap-4">
                                                        <DuesMakePaymentButton
                                                            data={data}
                                                            title={{ title: region.name }}
                                                            region={region}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className=" overflow-x-auto">
                        <Divider title="Optional Dues" variant="h5" />

                        {data?.data?.meta?.forums?.all && (
                            <div className="flex justify-between mb-4">
                                <div className="flex-1">Forum Dues </div>
                                <div className="flex-1 ml-4">
                                    <div className="flex justify-between">
                                        <div className="flex-1">
                                            GH¢{data?.data?.meta?.forums?.all}
                                        </div>
                                        <div className="flex-1 ml-4">
                                            <div className="flex-1 ml-4">
                                                {data?.data?.receipts?.some(
                                                    (item) =>
                                                        item.post_type === "inhouse_forum" &&
                                                        item.status === 1
                                                ) ? (
                                                    <ReceiptButton
                                                        id={
                                                            data?.data?.receipts?.find(
                                                                (item) =>
                                                                    item.post_type ===
                                                                    "inhouse_forum"
                                                            )?.id || null
                                                        }
                                                    />
                                                ) : (
                                                    <div className="flex w-max gap-4">
                                                        <DuesPayment
                                                            amount={data?.data?.meta?.forums?.all}
                                                            year={data.data.year}
                                                            id={data.data.id}
                                                            post_type="inhouse_forum"
                                                        />

                                                        <ManualPaymentButton
                                                            amount={data?.data?.meta?.forums?.all}
                                                            year={data?.data?.year}
                                                            id={data?.data?.id}
                                                            post_type="inhouse_forum"
                                                            apiFxn={normalDues}
                                                            check={data?.data?.receipts?.some(
                                                                (item) =>
                                                                    item.post_type ===
                                                                    "inhouse_forum"
                                                            )}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {data?.data?.meta?.women && (
                            <div className="flex justify-between mb-4">
                                <div className="flex-1">Women Dues </div>
                                <div className="flex-1 ml-4">
                                    <div className="flex justify-between">
                                        <div className="flex-1">
                                            GH¢
                                            {userYears
                                                ? data?.data?.meta?.women?.ten_or_more
                                                : data?.data?.meta?.women?.less_than_ten}
                                        </div>
                                        <div className="flex-1 ml-4">
                                            <div className="flex-1 ml-4">
                                                {data?.data?.receipts?.some(
                                                    (item) =>
                                                        item.post_type === "women" &&
                                                        item.status === 1
                                                ) ? (
                                                    <ReceiptButton
                                                        id={
                                                            data?.data?.receipts?.find(
                                                                (item) => item.post_type === "women"
                                                            )?.id || null
                                                        }
                                                    />
                                                ) : (
                                                    <div className="flex w-max gap-4">
                                                        <DuesPayment
                                                            amount={
                                                                userYears
                                                                    ? data?.data?.meta?.women
                                                                          ?.ten_or_more
                                                                    : data?.data?.meta?.women
                                                                          ?.less_than_ten
                                                            }
                                                            year={data.data.year}
                                                            id={data.data.id}
                                                            post_type="women"
                                                        />

                                                        <ManualPaymentButton
                                                            amount={
                                                                userYears
                                                                    ? data?.data?.meta?.women
                                                                          ?.ten_or_more
                                                                    : data?.data?.meta?.women
                                                                          ?.less_than_ten
                                                            }
                                                            year={data?.data?.year}
                                                            id={data?.data?.id}
                                                            post_type="women"
                                                            apiFxn={normalDues}
                                                            check={data?.data?.receipts?.some(
                                                                (item) => item.post_type === "women"
                                                            )}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default DuesPaymentPage;
