import {
  APPLY_STICKERS,
  GET_USER_STICKERS,
  SINGLE_STICKER,
  STICKER_PAYMENT,
  UPDATE_STICKER,
  UPLOAD_REG_STICKER,
  UPLOAD_SUPPORT_STICKER,
} from "../../constants/api_constants";
import { handleApi, handleFileUpload } from "../utils";

export const getUserStickers = async () => {
  return handleApi("get", GET_USER_STICKERS);
};

export const applyUserSticker = async (values) => {
  return handleApi("post", APPLY_STICKERS, values);
};

export const updateUserSticker = async (values) => {
  return handleApi("put", `${UPDATE_STICKER}/${values.id}`, values);
};

export const uploadRegDoc = async (id, value) => {
  return handleFileUpload(`${UPLOAD_REG_STICKER}/${id}`, value);
};

export const uploadSupportDoc = async (id, value) => {
  return handleFileUpload(`${UPLOAD_SUPPORT_STICKER}/${id}`, value);
};

export const stickerPayment = async (value) => {
  return handleApi("get", `${STICKER_PAYMENT}/${value}`);
};

export const singleSticker = async (value) => {
  return handleApi("get", `${SINGLE_STICKER}/${value}`);
};
