import {
  Button,
  Dialog,
  DialogFooter,
  DialogHeader,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

function ManualConference({ amount, year, id, apiFxn }) {
  const [manualLoading, setManualLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const handleOpen = () => setOpen(!open);

  const handleManual = () => {
    setManualLoading(true);
    const formData = {
      amount,
      year,
      id,
    };

    dispatch(apiFxn(formData)).finally(() => {
      setManualLoading(false);
      setOpen(true);
    });
  };

  return (
    <>
      <Button onClick={() => handleManual()} size="md" color="amber">
        {manualLoading ? <Spinner /> : "Manual Pay"}
      </Button>

      {open && (
        <Dialog open={open} handleOpen={handleOpen}>
          <DialogHeader>
            <Typography variant="h5" color="blue-gray">
              Manual Payment Successfully Added
            </Typography>
          </DialogHeader>
          <DialogFooter>
            <Button variant="text" color="blue-gray" onClick={handleOpen}>
              Close
            </Button>
          </DialogFooter>
        </Dialog>
      )}
    </>
  );
}

export default ManualConference;
