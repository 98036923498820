export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAIL = "SIGNIN_FAIL";

export const SIGNUP_CHECK_SUCCESS = "SIGNUP_CHECK_SUCCESS";
export const SIGNUP_CHECK_FAIL = "SIGNUP_CHECK_FAIL";

export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";

export const SET_USER_DATA = "SET_USER_DATA";

export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const ERROR_MESSAGE = "Something went wrong.";

export const LOGOUT = "LOGOUT";

export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const UPDATE_FAILURE = "UPDATE_FAILURE";

export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const PROFILE_UPLOAD_SUCCESS = "PROFILE_UPLOAD_SUCCESS";
export const PROFILE_UPLOAD_FAILURE = "PROFILE_UPLOAD_FAILURE";
