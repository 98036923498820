import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updatePupilage } from "../../../redux/actions/pupilage_action";

function RequestCard({ data }) {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAction = (item) => {
    if (item?.pupils_status?.id === 2) {
      navigate(`/home/pupilage/action/${item.id}`);
    }

    if (item?.pupils_status?.id === 5) {
      setLoading(true);
      const formData = {
        id: item.id,
        p_status: parseInt(item.p_status) + 1,
      };
      dispatch(updatePupilage(formData)).finally(() => {
        setLoading(false);
        window.location.reload();
      });
    }
  };

  return data.map((item, index) => (
    <Card key={index} className="mb-6">
      <CardBody>
        <div className="mb-2">
          <span>Pupil Name</span>
          <Typography variant="h5" color="black">
            {item.u_name.title +
              " " +
              item.u_name.first_name +
              " " +
              item.u_name.last_name +
              " - " +
              item.u_name.bar_number}
          </Typography>
        </div>

        <div className="flex items-center justify-between mb-2">
          <div className="w-1/2">
            <span>Start Date</span>
            <Typography variant="lead" color="black">
              {moment(item.start_date).format("MMMM Do YYYY")}
            </Typography>
          </div>
          <div className="w-1/2">
            <span>End Date</span>
            <Typography variant="lead" color="black">
              {item.end_date
                ? moment(item.end_date).format("MMMM Do YYYY")
                : "Not Completed"}
            </Typography>
          </div>
        </div>

        <div className="mb-2">
          <span>Pupil Status</span>
          <Typography variant="lead" color="pink">
            {item.pupils_status.name}
          </Typography>
        </div>
        {item.rejected && (
          <div className="mb-2">
            <span>Rejected Message</span>
            <Typography variant="lead" color="pink">
              {item.rejected}
            </Typography>
          </div>
        )}
      </CardBody>

      <CardFooter>
        {item.pupils_status.id === 2 || item.pupils_status.id === 5 ? (
          <Button color="green" onClick={() => handleAction(item)}>
            {loading ? <Spinner color="white" /> : "Approve"}
          </Button>
        ) : (
          <Button disabled>No Action</Button>
        )}
        <Typography color="black">Please refer any edits to pupil</Typography>
      </CardFooter>
    </Card>
  ));
}

export default RequestCard;
