import { Button } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function BootcampReceiptButton({ data }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/home/bootcamp/receipt", { state: { data } });
  };

  return (
    <Button color="blue-gray" onClick={handleClick}>
      View Receipt
    </Button>
  );
}

export default BootcampReceiptButton;
