import { Button } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function DuesMakePaymentButton({ data, title, region }) {
  const navigate = useNavigate();

  const formData = {
    data: { ...data, ...title, ...(region && { region: region }) },
  };

  const handleNavigate = () => {
    navigate("/home/dues/invoice", { state: { formData } });
  };

  return (
    <Button color="cyan" onClick={() => handleNavigate()}>
      Make Payment
    </Button>
  );
}

export default DuesMakePaymentButton;
