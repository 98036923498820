import { Chip } from "@material-tailwind/react";
import React from "react";

function RegionalStatus({ data, title }) {
  const status = data?.receipts.some(
    (item) => item.post_type === title && item.status === 1
  );

  return (
    <div className="flex ml-2">
      {status ? (
        <Chip color="green" value="Paid" variant="ghost" />
      ) : (
        <Chip color="amber" value="Not Paid" variant="ghost" />
      )}
    </div>
  );
}

export default RegionalStatus;
