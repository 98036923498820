import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  uploadRegFile,
  uploadSupportFile,
} from "../../../redux/actions/stickers_action";
import SectionTitle from "../../../common/shared/SectionTitle";
import AlertMessage from "../../../common/actions/AlertMessage";
import FileUpload from "../../../common/forms/FileUpload";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import StickerPay from "./StickerPay";

function StickersUpload() {
  const { id } = useParams();

  const [regLoading, setRegLoading] = useState(false);
  const [supportLoading, setSupportLoading] = useState(false);

  const dispatch = useDispatch();

  const { docs, files, stickerError } = useSelector((state) => state.sticker);

  const handleFileUpload = async (file, fileType) => {
    let setLoadingFunction;
    switch (fileType) {
      case "docs":
        setLoadingFunction = setRegLoading;
        await dispatch(uploadRegFile(id, file));
        break;

      case "files":
        setLoadingFunction = setSupportLoading;
        await dispatch(uploadSupportFile(id, file));
        break;
      default:
        break;
    }

    if (setLoadingFunction) {
      setLoadingFunction(false);
    }
  };

  return (
    <>
      <SectionTitle
        title="Upload Sticker Documents"
        description=""
        children={[]}
      />

      <div className="my-8 max-w-[1200px] m-auto px-4">
        {stickerError && <AlertMessage type="error" message={stickerError} />}

        <Card>
          <CardBody>
            <Typography color="blue-gray" variant="h5" className="mb-10">
              Upload Required Files
            </Typography>

            <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-10 my-10">
              <FileUpload
                label="Vehicle Registration Document"
                onUpload={(file) => handleFileUpload(file, "docs")}
                loading={regLoading}
                checked={docs}
              />

              <FileUpload
                label="Supporting Document"
                onUpload={(file) => handleFileUpload(file, "files")}
                loading={supportLoading}
                checked={files}
              />
            </div>

            <div className="mt-20">
              <Typography variant="lead" color="red" className="text-center">
                Please Upload Documents before proceeding to pay
              </Typography>
              <Typography
                variant="small"
                color="black"
                className="text-center mt-2"
              >
                Uploaded files must be an Image (.jpg, .jpeg), Word (.doc,
                .docx) or PDF (.pdf) and should not exceed 3MB
              </Typography>
            </div>
          </CardBody>
          <CardFooter className="flex justify-center">
            <StickerPay id={id} />
          </CardFooter>
        </Card>
      </div>
    </>
  );
}

export default StickersUpload;
