import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    removeChamberFile,
    singleUserChamber,
    uploadEvidence,
    uploadRGDFile,
    uploadSSNITFile,
    uploadVATFile,
} from "../../../redux/actions/chamber_actions";
import { Card, CardBody, Typography } from "@material-tailwind/react";
import FileUpload from "../../../common/forms/FileUpload";
import DeleteIconButton from "../../../common/buttons/DeleteIconButton";

function ChamberFiles() {
    const { id } = useParams();

    const [rgdLoading, setRGDLoading] = useState(false);
    const [vatLoading, setVatLoading] = useState(false);
    const [ssnitLoading, setSSNITLoading] = useState(false);
    const [evidenceLoading, setEvidenceLoading] = useState(false);

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const { rgd, vat, ssnit, evidence, data } = useSelector((state) => state.chamber);

    const handleFileUpload = async (file, fileType) => {
        let setLoadingFunction;
        switch (fileType) {
            case "rgd":
                setLoadingFunction = setRGDLoading;
                await dispatch(uploadRGDFile(id, file));
                break;

            case "vat":
                setLoadingFunction = setVatLoading;
                await dispatch(uploadVATFile(id, file));
                break;

            case "ssnit":
                setLoadingFunction = setSSNITLoading;
                await dispatch(uploadSSNITFile(id, file));
                break;

            case "evidence":
                setLoadingFunction = setEvidenceLoading;
                await dispatch(uploadEvidence(id, file));
                break;

            default:
                break;
        }

        if (setLoadingFunction) {
            setLoadingFunction(false);
        }
    };

    const handleFileRemove = async (value) => {
        const sendData = {
            id,
            fileType: value,
        };

        dispatch(removeChamberFile(sendData));
    };

    useEffect(() => {
        dispatch(singleUserChamber(id)).finally(() => setLoading(false));
    }, [dispatch, id]);

    return (
        <Card>
            <CardBody>
                <Typography color="blue-gray" variant="h5" className="mb-10">
                    Upload Required Files
                </Typography>
                <div className="grid md:grid-cols-2 gap-10 my-10">
                    <div>
                        <FileUpload
                            label="RGD Certificates"
                            onUpload={(file) => handleFileUpload(file, "rgd")}
                            helperText="(Form 3A-Unlimited Company, Form B - Act 152, Form A- Business Name)"
                            loading={rgdLoading}
                            checked={rgd}
                            // file={form}
                        />
                        {data?.files?.reg && (
                            <div className="flex justify-between">
                                <a
                                    href={`https://storage-gba.s3.eu-west-1.amazonaws.com/storage/${data?.files?.reg}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ color: "#3498db" }}
                                >
                                    Click Here to view file
                                </a>
                                <DeleteIconButton onClick={() => handleFileRemove("reg")} />
                            </div>
                        )}
                    </div>

                    <div>
                        <FileUpload
                            label="VAT Registration Certificate"
                            onUpload={(file) => handleFileUpload(file, "vat")}
                            loading={vatLoading}
                            checked={vat}
                        />

                        {data?.files?.vat && (
                            <div className="flex justify-between">
                                <a
                                    href={`https://storage-gba.s3.eu-west-1.amazonaws.com/storage/${data?.files?.vat}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ color: "#3498db" }}
                                >
                                    Click Here to view file
                                </a>
                                <DeleteIconButton onClick={() => handleFileRemove("vat")} />
                            </div>
                        )}
                    </div>
                </div>

                <div className="grid md:grid-cols-2 gap-10 my-10">
                    <div>
                        <FileUpload
                            label="SSNIT Registration Certificate"
                            onUpload={(file) => handleFileUpload(file, "ssnit")}
                            loading={ssnitLoading}
                            checked={ssnit}
                        />

                        {data?.files?.ssnit && (
                            <div className="flex justify-between">
                                <a
                                    href={`https://storage-gba.s3.eu-west-1.amazonaws.com/storage/${data?.files?.ssnit}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ color: "#3498db" }}
                                >
                                    Click Here to view file
                                </a>
                                <DeleteIconButton onClick={() => handleFileRemove("ssnit")} />
                            </div>
                        )}
                    </div>

                    <div>
                        <FileUpload
                            label="Evidence of Client's Account "
                            onUpload={(file) => handleFileUpload(file, "evidence")}
                            helperText="(Copy of blank cheque/ Letter of confirmation from Bankers)"
                            loading={evidenceLoading}
                            checked={evidence}
                        />

                        {data?.files?.evidence && (
                            <div className="flex justify-between">
                                <a
                                    href={`https://storage-gba.s3.eu-west-1.amazonaws.com/storage/${data?.files?.evidence}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ color: "#3498db" }}
                                >
                                    Click Here to view file
                                </a>
                                <DeleteIconButton onClick={() => handleFileRemove("evidence")} />
                            </div>
                        )}
                    </div>
                </div>

                <div className="mt-20">
                    <Typography variant="small" className="text-center">
                        By clicking to proceed below you affirm that the information and data you
                        have provided on this form are true; and that you are not knowingly
                        submitting information which is untrue, misleading or false.
                    </Typography>
                </div>
            </CardBody>
        </Card>
    );
}

export default ChamberFiles;
