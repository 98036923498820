import createAsyncAction from "../../utils/asyncAction";
import * as api from "../api/pupilage/pupilage_api";
import * as types from "../constants/pupilage_constants";

const singlePupilageTypes = {
  success: types.SINGLE_PUPILAGE_SUCCESS,
  failure: types.SINGLE_PUPILAGE_FAILURE,
};

const checkPupilageTypes = {
  success: types.CHECK_PUPILAGE_SUCCESS,
  failure: types.CHECK_PUPILAGE_FAILURE,
};

const updatePupilageTypes = {
  success: types.UPDATE_PUPILAGE_SUCCESS,
  failure: types.UPDATE_PUPILAGE_FAILURE,
};

const requestPupilageTypes = {
  success: types.REQUEST_PUPILAGE_SUCCESS,
  failure: types.REQUEST_PUPILAGE_FAILURE,
};

const actionPupilageTypes = {
  success: types.ACTION_PUPILAGE_SUCCESS,
  failure: types.ACTION_PUPILAGE_FAILURE,
};

const verifyPupilageTypes = {
  success: types.VERIFY_PUPILAGE_SUCCESS,
  failure: types.VERIFY_PUPILAGE_FAILURE,
};

const failedPupilageTypes = {
  success: types.FAILED_PUPILAGE_SUCCESS,
  failure: types.FAILED_PUPILAGE_FAILURE,
};

export const singlePupilage = createAsyncAction(
  api.singlePupilage,
  singlePupilageTypes
);

export const checkPupilage = createAsyncAction(
  api.checkPupilage,
  checkPupilageTypes
);

export const updatePupilage = createAsyncAction(
  api.updatePupilage,
  updatePupilageTypes
);

export const requestPupilage = createAsyncAction(
  api.requestPipulage,
  requestPupilageTypes
);

export const actionPupilage = createAsyncAction(
  api.actionPupilage,
  actionPupilageTypes
);

export const verifyPupilagePayment = createAsyncAction(
  api.verifyPupilagePayment,
  verifyPupilageTypes
);

export const failedPupilagePayment = createAsyncAction(
  api.failedPupilagePayment,
  failedPupilageTypes
);

// export const pupilagePayment = (formData) => async (dispatch) => {
//   try {
//     const response = await api.paymentPupilage(formData);
//     const { error, data } = response;

//     if (error) {
//       dispatch({ type: types.UPDATE_PUPILAGE_FAILURE, payload: error });
//     } else {
//       dispatch({ type: types.UPDATE_PUPILAGE_SUCCESS, payload: data });
//       window.location.reload();
//     }
//   } catch (error) {
//     dispatch({ type: types.UPDATE_PUPILAGE_FAILURE, payload: error });
//   }
// };

export const addPupilage = (formData) => async (dispatch) => {
  try {
    const response = await api.addPupilage(formData);
    const { error, data } = response;

    if (error) {
      dispatch({ type: types.ADD_PUPILAGE_FAILURE, payload: error });
    } else {
      dispatch({ type: types.ADD_PUPILAGE_SUCCESS, payload: data });
      window.location.reload();
    }
  } catch (error) {
    dispatch({ type: types.ADD_PUPILAGE_FAILURE, payload: error });
  }
};

export const getPupilageCert = (formData) => async (dispatch) => {
  try {
    await api.pupilageCert(formData);
  } catch (error) {
    dispatch({ type: types.SINGLE_PUPILAGE_FAILURE, payload: error });
  }
};
