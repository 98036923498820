import React from "react";
import Divider from "../../common/shared/Divider";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import AllReceiptButton from "./AllReceiptButton";

function ReceiptSection({ name, items }) {
  return (
    <div className="my-8">
      <Divider title={name} color="black" variant="h4" />

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-6 mt-10">
        {items &&
          items.map((item, index) => (
            <div key={index} className="card-wrapper">
              <Card>
                <CardBody>
                  <Typography variant="h5" color="blue-gray" className="mb-4">
                    {item.product.name}{" "}
                    {item.post_type === "dues"
                      ? " - National"
                      : item.post_type === "regdues"
                      ? " - Regional"
                      : null}
                  </Typography>
                </CardBody>
                <CardFooter>
                  <AllReceiptButton id={item.id} />
                </CardFooter>
              </Card>
            </div>
          ))}
      </div>
    </div>
  );
}

export default ReceiptSection;
