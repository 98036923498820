import React, { useEffect, useState } from "react";
import ReceiptSection from "./ReceiptSection";

function ReceiptList({ data }) {
  const [license, setLicense] = useState([]);
  const [dues, setDues] = useState([]);
  const [chambers, setChambers] = useState([]);
  const [pupilage, setPupilage] = useState([]);
  const [conference, setConference] = useState([]);

  useEffect(() => {
    data.sort((a, b) => (a.post_type > b.post_type ? 1 : -1));

    const sortedReceipts = {
      license: [],
      dues: [],
      chambers: [],
      pupilage: [],
      conference: [],
      stickers: [],
    };

    data.forEach((obj) => {
      switch (obj.post_type) {
        case "license":
          sortedReceipts.license.push(obj);
          break;
        case "dues":
          sortedReceipts.dues.push(obj);
          break;
        case "regdues":
          sortedReceipts.dues.push(obj);
          break;
        case "chambers":
          sortedReceipts.chambers.push(obj);
          break;
        case "pupilage":
          sortedReceipts.pupilage.push(obj);
          break;
        case "conference":
          sortedReceipts.conference.push(obj);
          break;
        case "sticker":
          sortedReceipts.stickers.push(obj);
          break;
        default:
          break;
      }
    });

    setLicense(sortedReceipts.license);
    setDues(sortedReceipts.dues);
    setChambers(sortedReceipts.chambers);
    setPupilage(sortedReceipts.pupilage);
    setConference(sortedReceipts.conference);
  }, [data]);

  return (
    <>
      {license.length > 0 && (
        <ReceiptSection name="License Receipts" items={license} />
      )}
      {dues.length > 0 && <ReceiptSection name="Dues Receipts" items={dues} />}
      {chambers.length > 0 && (
        <ReceiptSection name="Chambers Receipts" items={chambers} />
      )}
      {conference.length > 0 && (
        <ReceiptSection name="Conference Receipts" items={conference} />
      )}
      {pupilage.length > 0 && (
        <ReceiptSection name="Pupilage Receipts" items={pupilage} />
      )}
    </>
  );
}

export default ReceiptList;
