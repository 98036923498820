import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGlcPupil, updateGlcPupil } from "../../redux/actions/glc_action";
import SectionTitle from "../../common/shared/SectionTitle";
import AlertMessage from "../../common/actions/AlertMessage";
import Loader from "../../common/loader/Loader";
import AcceptIconButton from "../../common/buttons/AcceptIconButton";
import RejectIconButton from "../../common/buttons/RejectIconButton";
import { PUPILAGE_DATA } from "../../constants/constants";
import DataTable from "../../common/shared/DataTable";
import OpenModal from "../../common/actions/OpenModal";
import { Typography } from "@material-tailwind/react";
import InputArea from "../../common/forms/InputArea";
import NoContent from "../../common/shared/NoContent";

const link = [{ name: "Pupillage Request", link: "" }];

function GlcPupilage() {
  const [loading, setLoading] = useState(true);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const [formData, setFormData] = useState(PUPILAGE_DATA);
  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);

  const dispatch = useDispatch();

  const { data, glcError, glcSuccess } = useSelector((state) => state.glc);

  const columns = [
    {
      field: "full_name",
      headerName: "Pupil Name",
      flex: 1,
      renderCell: (params) => (
        <div className="flex flex-col">
          <Typography color="black" className="font-normal">
            {params.row.u_name.title +
              " " +
              params.row.u_name.first_name +
              " " +
              params.row.u_name.last_name}
          </Typography>
          <Typography variant="small" color="black">
            Bar #: {params.row.u_name.bar_number}
          </Typography>
          <Typography variant="small" color="black">
            DOC: {params.row.u_name.date_of_call}
          </Typography>
        </div>
      ),
    },
    {
      field: "master_name",
      headerName: "Master Name",
      flex: 1,
      renderCell: (params) => (
        <div className="flex flex-col">
          <Typography color="black" className="font-normal">
            {params.row.s_name.title +
              " " +
              params.row.s_name.first_name +
              " " +
              params.row.s_name.last_name}
          </Typography>
          <Typography variant="small" color="black">
            Bar#: {params.row.s_name.bar_number}
          </Typography>
          <Typography variant="small" color="black">
            DOC: {params.row.s_name.date_of_call}
          </Typography>
        </div>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      width: 180,
      renderCell: (params) => (
        <div className="flex flex-col">
          <Typography color="black" className="font-normal">
            Start: {params.row.start_date}
          </Typography>
          <Typography color="black" className="font-normal">
            End: {params.row.end_date ? params.row.end_date : ""}
          </Typography>
          <Typography color="black" className="font-normal">
            Sub: {params.row.updatedAt}
          </Typography>
        </div>
      ),
    },
    {
      field: "chamber_name",
      headerName: "Chamber Name",
      flex: 1,
      valueGetter: (params) => params.row.chamber_name.toUpperCase(),
    },

    // { field: "end_date", headerName: "End Date", width: 120 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      valueGetter: (params) => params.row.pupils_status.name,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 80,
      renderCell: (params) => (
        <div>
          <AcceptIconButton onClick={() => openAccept(params.row)} />
          <RejectIconButton onClick={() => openReject(params.row)} />
        </div>
      ),
    },
  ];

  // const columns = [
  //   {
  //     field: "full_name",
  //     headerName: "Pupil Name",
  //     // minWidth: 350,
  //     flex: 1,
  //     valueGetter: (params) =>
  //       params.row.u_name.title +
  //       " " +
  //       params.row.u_name.first_name +
  //       " " +
  //       params.row.u_name.last_name +
  //       " - " +
  //       params.row.u_name.bar_number,
  //   },
  //   {
  //     field: "pupil_call",
  //     headerName: "P. Date of Call",
  //     width: 120,
  //     valueGetter: (params) => params.row.u_name.date_of_call,
  //   },
  //   {
  //     field: "master_name",
  //     headerName: "Master Name",
  //     // minWidth: 350,
  //     flex: 1,
  //     valueGetter: (params) =>
  //       params.row.s_name.title +
  //       " " +
  //       params.row.s_name.first_name +
  //       " " +
  //       params.row.s_name.last_name +
  //       " - " +
  //       params.row.s_name.bar_number,
  //   },
  //   {
  //     field: "master_call",
  //     headerName: "S. Date of Call",
  //     width: 120,
  //     valueGetter: (params) => params.row.s_name.date_of_call,
  //   },
  //   {
  //     field: "chamber_name",
  //     headerName: "Chamber Name",
  //     // minWidth: 450,
  //     flex: 1,
  //     valueGetter: (params) => params.row.chamber_name.toUpperCase(),
  //   },
  //   { field: "start_date", headerName: "Start Date", width: 120 },
  //   { field: "end_date", headerName: "End Date", width: 120 },
  //   {
  //     field: "status",
  //     headerName: "Status",
  //     // minWidth: 450,
  //     flex: 1,
  //     valueGetter: (params) => params.row.pupils_status.name,
  //   },
  //   {
  //     field: "actions",
  //     headerName: "Actions",
  //     width: 80,
  //     renderCell: (params) => (
  //       <div>
  //         <AcceptIconButton onClick={() => openAccept(params.row)} />
  //         <RejectIconButton onClick={() => openReject(params.row)} />
  //       </div>
  //     ),
  //   },
  // ];

  const updateFormValue = ({ updateType, value }) => {
    setFormData({ ...formData, [updateType]: value });
  };

  const openAccept = (item) => {
    setAcceptModal(!acceptModal);
    setFormData(item);
  };

  const openReject = (item) => {
    setRejectModal(!rejectModal);
    setFormData(item);
  };

  const handleAccept = (e) => {
    e.preventDefault();
    setAcceptLoading(true);
    const acceptData = {
      id: formData.id,
      p_status: parseInt(formData.p_status) + 1,
    };
    dispatch(updateGlcPupil(acceptData)).finally(() => {
      setAcceptLoading(false);
      setAcceptModal(false);
    });
  };

  const handleReject = (e) => {
    e.preventDefault();
    setRejectLoading(true);
    const rejectData = {
      id: formData.id,
      rejected: formData.rejected,
      rejected_at: new Date(),
    };
    dispatch(updateGlcPupil(rejectData)).finally(() => {
      setRejectModal(false);
      setRejectLoading(false);
    });
  };

  useEffect(() => {
    dispatch(getGlcPupil()).finally(() => setLoading(false));
  }, [dispatch]);

  return (
    <>
      <SectionTitle title="Pupillage Request" description="" children={link} />

      <div className="my-8 max-w-[1200px] m-auto px-4">
        {glcError ? (
          <AlertMessage type="error" message={glcError} />
        ) : glcSuccess ? (
          <AlertMessage type="success" message={glcSuccess} />
        ) : null}

        {loading ? (
          <Loader />
        ) : (
          <>
            {data.length > 0 ? (
              <DataTable
                rows={data}
                columns={columns}
                dateFormat={["start_date", "end_date", "updatedAt"]}
                pageSize={10}
                // columnVisibilityModel={{ end_date: false, status: false }}
              />
            ) : (
              <NoContent message="No data to display" />
            )}

            {acceptModal && (
              <OpenModal
                open={acceptModal}
                handler={openAccept}
                title="Confirm Approval"
                loading={acceptLoading}
                actionTitle="Approve"
                handleSubmit={(e) => handleAccept(e)}
                children={
                  <Typography color="black" variant="paragraph">
                    Please confirm you want to{" "}
                    {formData.p_status === 2
                      ? `accept ${
                          formData?.u_name?.first_name +
                          " " +
                          formData?.u_name?.last_name
                        } pupil application?`
                      : `accept the end date of this ${
                          formData?.u_name?.first_name +
                          " " +
                          formData?.u_name?.last_name
                        } ?`}
                  </Typography>
                }
              />
            )}

            {rejectModal && (
              <OpenModal
                open={rejectModal}
                handler={openReject}
                title="Reject Pupil"
                loading={rejectLoading}
                actionTitle="Reject"
                handleSubmit={(e) => handleReject(e)}
                children={
                  <InputArea
                    defaultValue={formData.rejected}
                    updateType="rejected"
                    labelTitle="Reason"
                    updateFormValue={updateFormValue}
                    required={true}
                  />
                }
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default GlcPupilage;
