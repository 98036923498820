import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner } from "@material-tailwind/react";
import { usePaystackPayment } from "react-paystack";
import { updateDues } from "../../../redux/actions/dues_action";
import { useNavigate } from "react-router-dom";

function UpdatePaymentButton({ id, amount, data }) {
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.auth?.userData);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const receiptID = data.receipts.find((item) => item.post_type === "dues");

  let reference;

  const config = {
    reference: reference || new Date().getTime().toString(),
    email: user?.email,
    amount: amount * 100 + amount * 0.02 * 100,
    publicKey: "pk_live_68dddf36b26bb406911598f937c7a6b4071765e5",
    currency: "GHS",
    metadata: {
      section_id: data.id,
      bar_number: user.bar_number,
      phone: user?.phone,
    },
  };

  const initializePayment = usePaystackPayment(config);

  const onSuccess = (reference) => {
    const formData = {
      id: receiptID.id,
      amount,
    };
    setLoading(true);
    dispatch(updateDues(formData))
      .then(() => navigate("/home/dues"))
      .finally(() => setLoading(false));
  };

  const onClose = () => {
    reference = new Date().getTime().toString();
  };

  return (
    <Button color="pink" onClick={() => initializePayment(onSuccess, onClose)}>
      {loading ? <Spinner color="white" /> : "E-Payment"}
    </Button>
  );
}

export default UpdatePaymentButton;
