import React, { useState } from "react";
import { Input, Spinner, Typography } from "@material-tailwind/react";
import { API } from "../../redux/api/utils";
import { GET_USER_NAME } from "../../redux/constants/api_constants";

function InputCheck({
  labelTitle,
  type,
  containerStyle,
  defaultValue,
  placeholder,
  updateFormValue,
  updateType,
  required = false,
  maxLength,
}) {
  const [value, setValue] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [userText, setUserText] = useState(null);

  const updateInputValue = (val) => {
    setValue(val);
    setErrorText(null);
    setUserText(null);
    updateFormValue({ updateType, value: val });
  };

  const checkUser = async () => {
    setLoading(true);
    try {
      const response = await API({
        method: "get",
        url: `${GET_USER_NAME}/${value}`,
      });

      setUserText(response.data.user);
    } catch (e) {
      setUserText(null);
      setErrorText(e.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`form-control w-full ${containerStyle}`}>
      <Input
        type={type || "text"}
        label={labelTitle}
        variant="outlined"
        size="lg"
        value={value}
        placeholder={placeholder || ""}
        onChange={(e) => updateInputValue(e.target.value)}
        required={required}
        color="black"
        maxLength={maxLength ? maxLength : null}
        icon={loading && <Spinner color="pink" className="h-4 w-4" />}
        onBlur={checkUser}
      />
      {errorText ? (
        <Typography variant="small" color="red">
          Error: {errorText}
        </Typography>
      ) : userText ? (
        userText && (
          <Typography variant="small">
            {userText?.title} {userText?.first_name} {userText?.last_name}
          </Typography>
        )
      ) : null}
    </div>
  );
}

export default InputCheck;
