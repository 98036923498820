export const LOGIN_DATA = {
  username: "",
  password: "",
};

export const REGISTER_DATA = {
  bar_number: "",
  username: "",
  email: "",
  password: "",
  confirm_password: "",
};

export const FORGOT_DATA = {
  email: "",
};

export const PUPILAGE_DATA = {
  s_bar_number: "",
  chamber_num: "",
  start_date: "",
  p_region: "",
  end_date: "",
  p_status: "",
  rejected: "",
  rejected_at: "",
};

export const USER_DATA = {
  username: "",
  email: "",
  password: "",
  date_of_birth: "",
  tin: "",
  chamber_name: "",
  chamber_address: "",
  avatar: "",
  academic_q: "",
  phone: "",
  phone_sec: "",
  gender: "",
  p_region: "",
  practice_areas: [],
  primary_region: "",
  secondary_region: "",
  settings: {
    GLC: {
      email: Boolean,
      phone: Boolean,
      gender: Boolean,
      location: Boolean,
      phone_sec: Boolean,
      academic_q: Boolean,
      bar_number: Boolean,
      date_of_birth: Boolean,
      enrolment_date: Boolean,
      practice_areas: Boolean,
    },
    public: {
      email: Boolean,
      phone: Boolean,
      gender: Boolean,
      location: Boolean,
      phone_sec: Boolean,
      academic_q: Boolean,
      bar_number: Boolean,
      date_of_birth: Boolean,
      enrolment_date: Boolean,
      practice_areas: Boolean,
    },
    lawyers: {
      email: Boolean,
      phone: Boolean,
      gender: Boolean,
      location: Boolean,
      phone_sec: Boolean,
      academic_q: Boolean,
      bar_number: Boolean,
      date_of_birth: Boolean,
      enrolment_date: Boolean,
      practice_areas: Boolean,
    },
  },
};

export const CHANGE_PASSWORD_DATA = {
  password: "",
  new_password: "",
  confirm_password: "",
};

export const RESET_PASSWORD_DATA = {
  new_password: "",
  confirm_password: "",
};

export const CHAMBER_DATA = {
  name: "",
  type: "",
  reg_num: "",
  vat_num: "",
  tin_num: "",
  ssnit_num: "",
  ghpost: "",
  postal_add: "",
  address: "",
  telephone: "",
  fax: "",
  email: "",
  staff_num: "",
  clerks_num: "",
  accounts_part: "",
  accounts_full: "",
  equipments: "",
  head: "",
  deputy: "",
  reports: "",
  rejected_note: "",
  files: {
    rgd: "",
    ssnit: "",
    vat: "",
    evidence: "",
  },
  practice_areas: [],
  p_region: "",
  city: "",
};

export const CHAMBER_MEMBER_DATA = {
  id: "",
  bar_number: "",
  status: "",
  title: "",
  first_name: "",
  last_name: "",
  date_added: "",
};

export const STICKER_DATA = {
  docs: "",
  files: "",
  notes: "",
  qty: "",
  plate_a: "",
  plate_b: "",
  plate_c: "",
};
