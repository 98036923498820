import { Chip } from "@material-tailwind/react";
import React from "react";

function LicenseStatus({ status, manual }) {
  const check = status?.find((item) => item.status === 1);

  return (
    <div className="flex ml-2">
      {check || manual ? (
        <Chip color="green" value="Paid" variant="ghost" />
      ) : (
        <Chip color="amber" value="Not Paid" variant="ghost" />
      )}
    </div>
  );
}

export default LicenseStatus;
