import React, { useEffect, useState } from "react";
import SectionTitle from "../../common/shared/SectionTitle";
import { Typography, Card, CardBody } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import SettingsRadio from "./components/SettingsRadio";
import { getProfile } from "../../redux/actions/auth_action";
import { USER_DATA } from "../../constants/constants";
import Loader from "../../common/loader/Loader";
import { updateSettings } from "../../redux/actions/main_action";

const link = [{ name: "Settings", link: "" }];

function Settings() {
    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState(USER_DATA.settings);

    const dispatch = useDispatch();

    const userData = useSelector((state) => state.auth.profile);

    const handleSettingChange = (settingType, field, newValue) => {
        const updatedSetting = { ...settings };
        updatedSetting[settingType][field] = newValue;
        setSettings(updatedSetting);
        dispatch(
            updateSettings({
                settings: updatedSetting,
                bar_number: userData.bar_number,
            })
        );
    };

    useEffect(() => {
        dispatch(getProfile()).finally(() => setLoading(false));
    }, [dispatch]);

    useEffect(() => {
        if (userData) {
            setSettings(userData.settings);
        }
    }, [userData, settings]);

    return (
        <>
            <SectionTitle
                title="Lawyer Locator Settings"
                description="Please select section which should be visible to whoever access your details"
                children={link}
            />

            {loading ? (
                <Loader />
            ) : (
                <>
                    {Object.keys(settings).length > 0 && (
                        <div className="my-8 max-w-[1200px] m-auto px-4">
                            <div className="grid sx:grid-cols-1 md:grid-cols-2 gap-20 my-4">
                                {/* This card will be deleted */}
                                <Card className=" hidden">
                                    <CardBody>
                                        <div className="flex justify-between">
                                            <Typography variant="h4">
                                                Lawyers Info Access
                                            </Typography>
                                            {/* <div className="flex gap-4">
                    <Typography variant="paragraph">Visible</Typography>
                    <Typography variant="paragraph">Hide</Typography>
                  </div> */}
                                        </div>
                                        <SettingsRadio
                                            title="Email Address"
                                            settingType="lawyers"
                                            field="email"
                                            value={settings.lawyers.email}
                                            onChange={handleSettingChange}
                                        />
                                        <SettingsRadio
                                            title="Phone Number"
                                            settingType="lawyers"
                                            field="phone"
                                            value={settings?.lawyers.phone}
                                            onChange={handleSettingChange}
                                        />
                                        <SettingsRadio
                                            title="Gender"
                                            settingType="lawyers"
                                            field="gender"
                                            value={settings?.lawyers.gender}
                                            onChange={handleSettingChange}
                                        />
                                        <SettingsRadio
                                            title="Bar Number"
                                            settingType="lawyers"
                                            field="bar_number"
                                            value={settings?.lawyers.bar_number}
                                            onChange={handleSettingChange}
                                        />
                                        <SettingsRadio
                                            title="Date of Birth"
                                            settingType="lawyers"
                                            field="date_of_birth"
                                            value={settings?.lawyers.date_of_birth}
                                            onChange={handleSettingChange}
                                        />
                                        <SettingsRadio
                                            title="Date of Call"
                                            settingType="lawyers"
                                            field="enrolment_date"
                                            value={settings?.lawyers.enrolment_date}
                                            onChange={handleSettingChange}
                                        />

                                        <SettingsRadio
                                            title="Academic Qualifications"
                                            settingType="lawyers"
                                            field="academic_q"
                                            value={settings?.lawyers.academic_q}
                                            onChange={handleSettingChange}
                                        />
                                        <SettingsRadio
                                            title="Practice Areas"
                                            settingType="lawyers"
                                            field="practice_areas"
                                            value={settings?.lawyers.practice_areas}
                                            onChange={handleSettingChange}
                                        />
                                        <SettingsRadio
                                            title="Practice Region"
                                            settingType="lawyers"
                                            field="location"
                                            value={settings?.lawyers.location}
                                            onChange={handleSettingChange}
                                        />
                                    </CardBody>
                                </Card>

                                <Card>
                                    <CardBody>
                                        <div className="flex justify-between">
                                            <Typography variant="h4">Public Info Access</Typography>
                                            {/* <div className="flex gap-4">
                    <Typography variant="paragraph">Visible</Typography>
                    <Typography variant="paragraph">Hide</Typography>
                  </div> */}
                                        </div>
                                        <SettingsRadio
                                            title="Email Address"
                                            settingType="public"
                                            field="email"
                                            value={settings.public.email}
                                            onChange={handleSettingChange}
                                        />
                                        <SettingsRadio
                                            title="Phone Number"
                                            settingType="public"
                                            field="phone"
                                            value={settings?.public.phone}
                                            onChange={handleSettingChange}
                                        />
                                        <SettingsRadio
                                            title="Gender"
                                            settingType="public"
                                            field="gender"
                                            value={settings?.public.gender}
                                            onChange={handleSettingChange}
                                        />
                                        <SettingsRadio
                                            title="Bar Number"
                                            settingType="public"
                                            field="bar_number"
                                            value={settings?.public.bar_number}
                                            onChange={handleSettingChange}
                                        />
                                        <SettingsRadio
                                            title="Date of Birth"
                                            settingType="public"
                                            field="date_of_birth"
                                            value={settings?.public.date_of_birth}
                                            onChange={handleSettingChange}
                                        />
                                        <SettingsRadio
                                            title="Date of Call"
                                            settingType="public"
                                            field="enrolment_date"
                                            value={settings?.public.enrolment_date}
                                            onChange={handleSettingChange}
                                        />
                                        <SettingsRadio
                                            title="Academic Qualifications"
                                            settingType="public"
                                            field="academic_q"
                                            value={settings?.public.academic_q}
                                            onChange={handleSettingChange}
                                        />
                                        <SettingsRadio
                                            title="Practice Areas"
                                            settingType="public"
                                            field="practice_areas"
                                            value={settings?.public.practice_areas}
                                            onChange={handleSettingChange}
                                        />
                                        <SettingsRadio
                                            title="Practice Region"
                                            settingType="public"
                                            field="location"
                                            value={settings?.public.location}
                                            onChange={handleSettingChange}
                                        />
                                    </CardBody>
                                </Card>

                                <Card>
                                    <CardBody>
                                        <div className="flex justify-between">
                                            <Typography variant="h4">GLC</Typography>
                                            {/* <div className="flex gap-4">
                    <Typography variant="paragraph">Visible</Typography>
                    <Typography variant="paragraph">Hide</Typography>
                  </div> */}
                                        </div>
                                        <SettingsRadio
                                            title="Email Address"
                                            settingType="GLC"
                                            field="email"
                                            value={settings.GLC.email}
                                            onChange={handleSettingChange}
                                            disable={true}
                                        />
                                        <SettingsRadio
                                            title="Phone Number"
                                            settingType="GLC"
                                            field="phone"
                                            value={settings?.GLC.phone}
                                            onChange={handleSettingChange}
                                            disable={true}
                                        />
                                        <SettingsRadio
                                            title="Gender"
                                            settingType="GLC"
                                            field="gender"
                                            value={settings?.GLC.gender}
                                            onChange={handleSettingChange}
                                            disable={true}
                                        />
                                        <SettingsRadio
                                            title="Bar Number"
                                            settingType="GLC"
                                            field="bar_number"
                                            value={settings?.GLC.bar_number}
                                            onChange={handleSettingChange}
                                            disable={true}
                                        />
                                        <SettingsRadio
                                            title="Date of Birth"
                                            settingType="GLC"
                                            field="date_of_birth"
                                            value={settings?.GLC.date_of_birth}
                                            onChange={handleSettingChange}
                                            disable={true}
                                        />
                                        <SettingsRadio
                                            title="Date of Call"
                                            settingType="GLC"
                                            field="enrolment_date"
                                            value={settings?.GLC.enrolment_date}
                                            onChange={handleSettingChange}
                                            disable={true}
                                        />
                                        <SettingsRadio
                                            title="Academic Qualifications"
                                            settingType="GLC"
                                            field="academic_q"
                                            value={settings?.GLC.academic_q}
                                            onChange={handleSettingChange}
                                            disable={true}
                                        />
                                        <SettingsRadio
                                            title="Practice Areas"
                                            settingType="GLC"
                                            field="practice_areas"
                                            value={settings?.GLC.practice_areas}
                                            onChange={handleSettingChange}
                                            disable={true}
                                        />
                                        <SettingsRadio
                                            title="Practice Region"
                                            settingType="GLC"
                                            field="location"
                                            value={settings?.GLC.location}
                                            onChange={handleSettingChange}
                                            disable={true}
                                        />
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}

export default Settings;
