import React, { useEffect, useState } from "react";
import { REGISTER_DATA } from "../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { signUpAction, signUpCheck } from "../../redux/actions/auth_action";
import { Button, Card, Spinner, Typography } from "@material-tailwind/react";
import AlertMessage from "../../common/actions/AlertMessage";
import InputText from "../../common/forms/InputText";
import Modal from "../../common/shared/Modal";
import InputCheck from "../../common/forms/InputCheck";

function SignUp() {
  const [loading, setLoading] = useState(false);
  const [registerData, setRegisterData] = useState(REGISTER_DATA);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signUpError = useSelector((state) => state.auth?.signUpError);

  const updateFormValue = ({ updateType, value }) => {
    setError("");
    setRegisterData({ ...registerData, [updateType]: value });
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await dispatch(signUpAction(registerData, navigate));
    setLoading(false);
    setIsModalOpen(false);
  };

  const handleCheckSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (registerData.password === registerData.confirm_password) {
      await dispatch(signUpCheck(registerData.bar_number, setIsModalOpen));
    } else {
      setError("Passwords do not match. Please check and try again");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (signUpError) {
      setError(signUpError);
    }
  }, [signUpError]);

  const user = JSON.parse(localStorage.getItem("check"))?.user;

  return (
    <div className="min-h-screen flex items-center">
      <Card className="mx-auto w-full max-w-xl shadow-xl">
        <div className="py-24 px-10">
          <div className="text-center">
            <img src="./gba-logo.png" alt="gba logo" className="inline-block" />
            <Typography color="black" variant="h3" className="mb-2 mt-8">
              Register Account
            </Typography>
          </div>
          <form onSubmit={(e) => handleCheckSubmit(e)}>
            <div className="mb-16 mt-12">
              {error && <AlertMessage type="error" message={error} />}

              <InputCheck
                type="text"
                defaultValue={registerData.bar_number}
                updateType="bar_number"
                containerStyle="mt-4 mb-4"
                labelTitle="Bar Number"
                updateFormValue={updateFormValue}
                required={true}
              />

              {/* <InputText
                type="text"
                defaultValue={registerData.bar_number}
                updateType="bar_number"
                containerStyle="mt-4 mb-4"
                labelTitle="Bar Number"
                updateFormValue={updateFormValue}
                required={true}
              /> */}

              <hr />

              <InputText
                type="text"
                defaultValue={registerData.username}
                updateType="username"
                containerStyle="mt-4"
                labelTitle="Username"
                updateFormValue={updateFormValue}
                required={true}
              />

              <InputText
                type="email"
                defaultValue={registerData.email}
                updateType="email"
                containerStyle="mt-4"
                labelTitle="Email Address"
                updateFormValue={updateFormValue}
                required={true}
              />

              <InputText
                type="password"
                defaultValue={registerData.password}
                updateType="password"
                containerStyle="mt-4"
                labelTitle="Password"
                updateFormValue={updateFormValue}
                required={true}
              />

              <InputText
                type="password"
                defaultValue={registerData.confirm_password}
                updateType="confirm_password"
                containerStyle="mt-4"
                labelTitle="Confirm Password"
                updateFormValue={updateFormValue}
                required={true}
              />
            </div>

            <div className="text-center mt-16 mb-16">
              <Typography color="black" variant="small" className="mb-2 mt-8">
                By clicking to proceed below you affirm that the information and
                data you have provided on this form are true; and that you are
                not knowingly submitting information which is untrue, misleading
                or false.
              </Typography>
            </div>

            <Button
              variant="filled"
              size="lg"
              fullWidth={true}
              className="text-center flex items-center justify-center"
              type="submit"
            >
              {loading ? <Spinner color="white" /> : "Register"}
            </Button>

            <div className="mt-4 text-center">
              <Link to="/">
                <Typography variant="small" color="pink">
                  Already have an account? Login Here
                </Typography>
              </Link>
            </div>
          </form>
        </div>
      </Card>

      {isModalOpen && user && (
        <Modal
          title="Confirm Details"
          setOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          handleConfirm={(e) => handleSignUpSubmit(e)}
          loading={loading}
          children={
            <Typography variant="small" color="black">
              Please confirm you are{" "}
              <strong>
                {user.title} {user.first_name} {user.last_name}
              </strong>{" "}
              with Bar Number:<strong>{user.bar_number}</strong>
            </Typography>
          }
        />
      )}
    </div>
  );
}

export default SignUp;
