import { Button } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function ConferenceReceiptButton({
  id,
  full = true,
  disabled = false,
  text = "Registered",
  link,
}) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
  };

  return (
    <Button
      color="blue-gray"
      onClick={handleClick}
      disabled={disabled || id === null}
    >
      {disabled ? "Exempted" : "View Receipt"}
    </Button>
  );
}

export default ConferenceReceiptButton;
