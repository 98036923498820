import * as types from "../constants/auth_constants";

const initialState = {
  userData: null,
  accessToken: null,
  signInError: null,
  signUpError: null,
  updateError: null,
  forgotError: null,
  resetError: null,
  successMsg: null,
  profile: null,
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_ACCESS_TOKEN:
      return { ...state, accessToken: payload ? payload : null };

    case types.SET_USER_DATA:
      return { ...state, userData: payload ? payload : null };

    case types.SIGNUP_CHECK_FAIL:
    case types.SIGNUP_FAIL:
      return { ...state, signUpError: payload ? payload : [] };

    case types.SIGNUP_SUCCESS:
    case types.SIGNIN_SUCCESS:
      return {
        ...state,
        userData: payload ? payload.user : null,
        accessToken: payload ? payload.token : null,
        signInError: null,
        signUpError: null,
        forgotError: null,
        resetError: null,
      };

    case types.UPDATE_SUCCESS:
      return {
        ...state,
        userData: payload ? payload.user : null,
        accessToken: payload ? payload.token : null,
      };

    case types.FORGOT_PASSWORD_SUCCESS:
      return { ...state, payload: payload ? payload : null, forgotError: null };

    case types.FORGOT_PASSWORD_FAILURE:
      return { ...state, forgotError: payload ? payload : null };

    case types.RESET_PASSWORD_FAILURE:
      return { ...state, resetError: payload ? payload : null };

    case types.SIGNIN_FAIL:
      return { ...state, signInError: payload ? payload : null };

    case types.SIGNUP_CHECK_SUCCESS:
      return { ...state, userData: payload ? payload.user : null };

    case types.GET_PROFILE_SUCCESS:
    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        profile: payload ? payload.user : null,
        updateError: null,
      };

    case types.UPDATE_FAILURE:
    case types.GET_PROFILE_FAILURE:
    case types.CHANGE_PASSWORD_FAILURE:
      return { ...state, updateError: payload ? payload : null };

    case types.LOGOUT:
      return { ...state, userData: null, accessToken: null };

    default:
      return state;
  }
};

export default authReducer;
