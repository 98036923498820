import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserChamber, manualChamber, updateChamber } from "../../redux/actions/chamber_actions";
import SectionTitle from "../../common/shared/SectionTitle";
import AlertMessage from "../../common/actions/AlertMessage";
import Loader from "../../common/loader/Loader";
import NoContent from "../../common/shared/NoContent";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Dialog,
    DialogFooter,
    DialogHeader,
    List,
    ListItem,
    Spinner,
    Typography,
} from "@material-tailwind/react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ChamberButtons from "./components/ChamberButtons";
import ChamberMakePayment from "./components/ChamberMakePayment";
import Modal from "../../common/shared/Modal";
import OpenModal from "../../common/actions/OpenModal";

const link = [{ name: "Chambers & Legal Department" }];

function Chambers() {
    const [loading, setLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [certModal, setCertModal] = useState(false);
    const [certificate, setCertificate] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { data, chamberError } = useSelector((state) => state.chamber);
    const user = useSelector((state) => state.auth?.userData);

    const currentDate = new Date().getFullYear();

    const handleNavigation = () => {
        navigate("/home/chamber/new");
    };

    const handleSubmission = (id) => {
        setSubmitLoading(true);
        const formData = {
            id: id,
            stage: 1,
        };
        dispatch(updateChamber(formData, navigate)).finally(() => setSubmitLoading(false));
    };

    const handleManual = (e, item) => {
        e.preventDefault();
        const formData = {
            amount: 0,
            year: item.exp_date ? new Date(item.exp_date).getFullYear() : currentDate,
            chamberId: item.id,
        };

        setLoading(true);
        dispatch(manualChamber(formData)).finally(() => {
            setLoading(false);
            setOpen(true);
        });
    };

    const handleOpen = () => setOpen(!open);

    const handleOpenCert = (item) => {
        setCertModal(!certModal);
        setCertificate(item);
    };

    const viewCertificate = (item) => {
        const formData = {
            year: item.year,
            cert_number: item.cert_number,
            name: item.chamber.name,
            exp_date: item.chamber.exp_date,
        };

        navigate("/home/chamber/certificate", { state: { formData } });
    };

    useEffect(() => {
        dispatch(getUserChamber()).finally(() => {
            setLoading(false);
        });
    }, [dispatch]);

    return (
        <>
            <SectionTitle
                title="Chambers & Legal Department"
                description=""
                children={link}
                link={"/home"}
            />

            <div className="my-8 max-w-[1200px] m-auto px-4">
                {chamberError && <AlertMessage type="error" message={chamberError} />}

                {open && (
                    <Dialog open={open} handleOpen={handleOpen}>
                        <DialogHeader>
                            <Typography variant="h5" color="blue-gray">
                                Manual Payment Successfully Added
                            </Typography>
                        </DialogHeader>
                        <DialogFooter>
                            <Button variant="text" color="blue-gray" onClick={handleOpen}>
                                Close
                            </Button>
                        </DialogFooter>
                    </Dialog>
                )}

                {loading ? (
                    <Loader />
                ) : data.length === 0 ? (
                    <>
                        <Button color="pink" className="mb-4" onClick={handleNavigation}>
                            Apply for New Chamber
                        </Button>

                        <NoContent message="No Data to display" />
                    </>
                ) : (
                    <>
                        <Button color="pink" className="mb-4" onClick={handleNavigation}>
                            Apply for New Chamber
                        </Button>
                        {data.map((item, index) => (
                            <Card key={index} className="mb-6">
                                <CardBody>
                                    <Typography variant="h5" color="black">
                                        {item.name}
                                    </Typography>
                                    <div className="flex ml-4 mb-8">
                                        <ul className="flex flex-col md:flex-row md:space-x-8 list-disc">
                                            <li className="py-1">
                                                {item.headOf?.first_name +
                                                    " " +
                                                    item.headOf?.last_name +
                                                    " - " +
                                                    item.headOf?.bar_number}
                                            </li>
                                            {item.deputyOf && (
                                                <li className="py-1">
                                                    {item.deputyOf?.first_name +
                                                        " " +
                                                        item.deputyOf?.last_name +
                                                        " - " +
                                                        item.deputyOf?.bar_number}
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    {(item.stage === 4 && item.exp_date) || item.exp_date ? (
                                        <>
                                            <div className="mb-4">
                                                <Typography variant="paragraph">
                                                    Expires On:
                                                </Typography>
                                                <Typography
                                                    variant="lead"
                                                    color={item.alertExpire ? "red" : "black"}
                                                >
                                                    <span className="font-bold">
                                                        {moment(item.exp_date).format(
                                                            "MMMM Do YYYY"
                                                        )}
                                                    </span>
                                                </Typography>
                                            </div>

                                            <Typography variant="paragraph">
                                                Chamber Number:
                                            </Typography>
                                            <Typography variant="lead">
                                                <span className="font-bold">
                                                    {item.chamber_num}
                                                </span>
                                            </Typography>
                                        </>
                                    ) : (
                                        <>
                                            <Typography variant="paragraph">
                                                Application Status:
                                            </Typography>
                                            <Typography variant="lead" color="black">
                                                {item.chamber_status.name}
                                            </Typography>
                                        </>
                                    )}

                                    {item.rejected && !item.exp_date && (
                                        <div className="mt-8">
                                            <Typography variant="paragraph">
                                                Rejected Reason
                                            </Typography>
                                            <Typography variant="lead" color="red">
                                                {item.rejected_note}
                                            </Typography>
                                        </div>
                                    )}
                                </CardBody>

                                <CardFooter className="flex flex-col md:flex-row  item-center gap-3">
                                    <>
                                        {(user.bar_number === item.head ||
                                            user.bar_number === item.deputy) && (
                                            <Button
                                                color="blue"
                                                onClick={() =>
                                                    navigate(`/home/chamber/edit/${item.id}`)
                                                }
                                            >
                                                Edit Details
                                            </Button>
                                        )}

                                        {item.exp_date && <ChamberButtons item={item} />}

                                        {item.alertExpire &&
                                            (item.head === user.bar_number ||
                                                item.deputy === user.bar_number) && (
                                                <ChamberMakePayment data={item} />
                                            )}
                                    </>

                                    {item.stage === 0 && !item.exp_date && (
                                        <Button
                                            color="blue-gray"
                                            type="button"
                                            onClick={() => handleSubmission(item.id)}
                                        >
                                            {submitLoading ? (
                                                <Spinner className="h-4 w-4" />
                                            ) : (
                                                "Submit Application"
                                            )}
                                        </Button>
                                    )}

                                    {item.stage === 4 && !item.exp_date && (
                                        <ChamberMakePayment data={item} />
                                    )}

                                    {item.exp_date && (
                                        <Button onClick={() => handleOpenCert(item.prevCert)}>
                                            All Certificates
                                        </Button>
                                    )}
                                </CardFooter>
                            </Card>
                        ))}

                        {certModal && (
                            <OpenModal
                                open={certModal}
                                size="sm"
                                handler={handleOpenCert}
                                title="Previous Certificates"
                                actionTitle="Done"
                                children={
                                    <>
                                        <List>
                                            {certificate.map((item, index) => (
                                                <ListItem
                                                    key={index}
                                                    onClick={() => viewCertificate(item)}
                                                >
                                                    {item.year}
                                                </ListItem>
                                            ))}
                                        </List>
                                    </>
                                }
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
}

export default Chambers;
