import { Button } from "@material-tailwind/react";
import React from "react";

function ClearedButton({ manual }) {
  return (
    <Button fullWidth disabled>
      {manual ? "Paid" : "Please contact the secretariat"}
    </Button>
  );
}

export default ClearedButton;
