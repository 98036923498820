import * as types from "../constants/license_constants";

const initialState = {
  data: null,
  licenseError: null,
  manual: null,
};

const licenseReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_LICENSE_SUCCESS:
    case types.MANUAL_LICENSE_SUCCESS:
    case types.VERIFY_LICENSE_SUCCESS:
    case types.FAILED_LICENSE_SUCCESS:
    case types.ORDER_LICENSE_REF_SUCCESS:
      return { ...state, data: payload ? payload : null, licenseError: null };

    case types.LICENSE_PAYMENT_SUCCESS:
      return { ...state, manual: payload ? payload : null, licenseError: null };

    case types.GET_LICENSE_FAILURE:
    case types.LICENSE_PAYMENT_FAILURE:
    case types.MANUAL_LICENSE_FAILURE:
    case types.VERIFY_LICENSE_FAILURE:
    case types.FAILED_LICENSE_FAILURE:
    case types.ORDER_LICENSE_REF_FAILURE:
      return { ...state, licenseError: payload ? payload : null };

    default:
      return state;
  }
};

export default licenseReducer;
