import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SectionTitle from "../../../common/shared/SectionTitle";
import ReactToPrint from "react-to-print";
import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import logo from "../../../assets/img/gba-logo.png";
import moment from "moment";
import BootcampEPaymentButton from "./BootcampEPaymentButton";
import BootcampManualPayment from "./BootcampManualPayment";

function BootcampInvoice() {
  const location = useLocation();

  let cardBodyRef = useRef();
  const user = useSelector((state) => state.auth?.userData);

  const confData = location.state?.data;
  return (
    <div className="my-8 max-w-[1200px] m-auto px-4">
      <SectionTitle title="Bootcamp Invoice" description="" children={[]} />

      <div className="flex items-center justify-center gap-3 mb-8">
        <div>
          <ReactToPrint
            trigger={() => <Button>Print Invoice</Button>}
            content={() => cardBodyRef}
            removeAfterPrint={false}
          />
        </div>
        <>
          <BootcampEPaymentButton />
          <BootcampManualPayment />
        </>
      </div>

      <div className="flex items-center justify-center mb-8">
        <Typography variant="h5" color="red">
          Electronic payment attracts a service charge of 2% (GH¢
          {confData?.amount * 0.02})
        </Typography>
      </div>

      <Card>
        <CardBody ref={(el) => (cardBodyRef = el)}>
          <img src={logo} alt="logo" className="h-20" />

          <div className="mt-5 pl-3 flex justify-between">
            <div>
              <Typography variant="h5">Ghana Bar Association</Typography>
              <Typography variant="paragraph">
                H/NO. 5, 2nd Ave. State House Road,
              </Typography>
              <Typography variant="paragraph">
                South-East Ridge, Adjacent to
              </Typography>
              <Typography variant="paragraph">
                Ghana International Press Centre,
              </Typography>
              <Typography variant="paragraph">Accra</Typography>
            </div>

            <div>
              <Typography variant="h5">
                Billed to:{" "}
                {user.title + " " + user.first_name + " " + user.last_name}
              </Typography>
              <Typography variant="paragraph">
                Email Address: {user.email}
              </Typography>
              <Typography variant="paragraph">Phone #: {user.phone}</Typography>

              <div className="mt-20">
                <Typography variant="h6" color="gray">
                  Invoice Date
                </Typography>
                <Typography variant="h4">
                  {moment().format("MMMM Do, YYYY")}
                </Typography>
              </div>
            </div>
          </div>

          <div className="mt-24">
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      Description
                    </Typography>
                  </th>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      Qty
                    </Typography>
                  </th>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      Amount
                    </Typography>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {confData?.name}
                    </Typography>
                  </td>

                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      1
                    </Typography>
                  </td>

                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {confData?.amount}
                    </Typography>
                  </td>
                </tr>

                <tr>
                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-semibold"
                    >
                      Total
                    </Typography>
                  </td>

                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      GH¢
                    </Typography>
                  </td>

                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-semibold"
                    >
                      {confData?.amount}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="mt-40">
            <Typography variant="h4">Thank you!</Typography>
            <Typography variant="paragraph">
              If you encounter any issue related to the invoice you can contact
              us at:
            </Typography>

            <Typography variant="paragraph">
              email: <span className="font-semibold">info@ghanabar.org</span>
            </Typography>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default BootcampInvoice;
