import { Button } from "@material-tailwind/react";
import React from "react";
import { usePaystackPayment } from "react-paystack";
import { useDispatch, useSelector } from "react-redux";
import { conference_payment } from "../../../redux/actions/conference_action";
import { useNavigate } from "react-router-dom";

function ConferencePayment({ data, disable }) {
  const user = useSelector((state) => state.auth?.userData);

  const dispatch = useDispatch();

  let reference;

  const config = {
    reference: reference || new Date().getTime().toString(),
    email: user?.email,
    amount: data.amount * 100 + data.amount * 0.02 * 100,
    publicKey: "pk_live_4d578b0c780e68a75216bf00e17a62389b7aebaa",
    currency: "GHS",
    metadata: {
      section_id: data.id,
      bar_number: user.bar_number,
      phone: user?.phone,
    },
  };

  const initializePayment = usePaystackPayment(config);

  const onSuccess = (reference) => {
    const refData = {
      id: data.receiptStatus.id,
      status: 1,
    };

    dispatch(conference_payment(refData));
  };

  const onClose = () => {
    reference = new Date().getTime().toString();
  };

  const navigate = useNavigate();

  const formData = {
    data,
  };

  return (
    <Button
      color="pink"
      onClick={() =>
        navigate("/home/conference/invoice", { state: { formData } })
      }
      disabled={disable}
    >
      Make Payment
    </Button>
  );
}

export default ConferencePayment;
