import createAsyncAction from "../../utils/asyncAction";
import * as api from "../api/bootcamp/bootcamp_api";
import * as types from "../constants/bootcamp_constants";

const bootcampRegistrationTypes = {
  success: types.BOOTCAMP_REGISTRATION_SUCCESS,
  failure: types.BOOTCAMP_REGISTRATION_FAILURE,
};

const bootcampUserTypes = {
  success: types.BOOTCAMP_USER_SUCCESS,
  failure: types.BOOTCAMP_USER_FAILURE,
};

const bootcampUpdateTypes = {
  success: types.BOOTCAMP_UPDATE_SUCCESS,
  failure: types.BOOTCAMP_UPDATE_FAILURE,
};

const bootcampEPaymentTypes = {
  success: types.BOOTCAMP_LIVE_PAYMENT_SUCCESS,
  failure: types.BOOTCAMP_LIVE_PAYMENT_FAILURE,
};

const bootcampManualPaymentTypes = {
  success: types.BOOTCAMP_MANUAL_PAYMENT_SUCCESS,
  failure: types.BOOTCAMP_MANUAL_PAYMENT_FAILURE,
};

export const bootcampRegistrationAction = createAsyncAction(
  api.userBootcampRegistration,
  bootcampRegistrationTypes
);

export const bootcampUserAction = createAsyncAction(
  api.userBootcamp,
  bootcampUserTypes
);

export const bootcampUpdateAction = createAsyncAction(
  api.userBootcampUpdate,
  bootcampUpdateTypes
);

export const bootcampEPaymentAction = createAsyncAction(
  api.userBootcampEPayment,
  bootcampEPaymentTypes
);

export const bootcampManualPaymentAction = createAsyncAction(
  api.userBootcampManualPayment,
  bootcampManualPaymentTypes
);
