import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as api from "../../../redux/api/chamber/chamber_api";
import * as types from "../../../redux/constants/chamber_constants";

import { Button, Spinner } from "@material-tailwind/react";

function ChamberPayment({ data }) {
  const [liveLoading, setLiveLoading] = useState(false);

  const dispatch = useDispatch();

  const handleLive = async (e) => {
    e.preventDefault();

    setLiveLoading(true);
    const formData = {
      productId: data.product.id ? data.product.id : null,
      amount: data.exp_date
        ? data.product.meta.renewal
        : data.product.meta.application,
      serial: data.serial_number,
      year: data.exp_date
        ? new Date(data.exp_date).getFullYear()
        : new Date().getFullYear(),
      chamberId: data.id,
    };

    try {
      const response = await api.chamberPayment(formData);
      const { error, data } = response;

      if (error) {
        setLiveLoading(false);
        dispatch({ type: types.CHAMBER_PAYMENT_FAILURE, payload: error });
      } else {
        const firstItem = data.chamber[0];

        if (firstItem && firstItem.new_receipt.url) {
          window.location.href = firstItem.new_receipt.url;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Button color="pink" onClick={(e) => handleLive(e)}>
      {liveLoading ? <Spinner color="white" /> : "Make Payment"}
    </Button>
  );
}

export default ChamberPayment;
