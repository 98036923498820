export const GET_USER_STIKER_SUCCESS = "GET_USER_STIKER_SUCCESS";
export const GET_USER_STIKER_FAILURE = "GET_USER_STIKER_FAILURE";

export const APPLY_STICKER_SUCCESS = "APPLY_STICKER_SUCCESS";
export const APPLY_STICKER_FAILURE = "APPLY_STICKER_FAILURE";

export const UPDATE_STICKER_SUCCESS = "UPDATE_STICKER_SUCCESS";
export const UPDATE_STICKER_FAILURE = "UPDATE_STICKER_FAILURE";

export const UPLOAD_REG_STICKER_SUCCESS = "UPLOAD_REG_STICKER_SUCCESS";
export const UPLOAD_REG_STICKER_FAILURE = "UPLOAD_REG_STICKER_FAILURE";

export const UPLOAD_SUPPORT_STICKER_SUCCESS = "UPLOAD_SUPPORT_STICKER_SUCCESS";
export const UPLOAD_SUPPORT_STICKER_FAILURE = "UPLOAD_SUPPORT_STICKER_FAILURE";

export const STICKER_PAYMENT_SUCCESS = "STICKER_PAYMENT_SUCCESS";
export const STICKER_PAYMENT_FAILURE = "STICKER_PAYMENT_FAILURE";

export const SINGLE_STICKER_SUCCESS = "SINGLE_STICKER_SUCCESS";
export const SINGLE_STICKER_FAILURE = "SINGLE_STICKER_FAILURE";
