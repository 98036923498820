export const BOOTCAMP_REGISTRATION_SUCCESS = "BOOTCAMP_REGISTRATION_SUCCESS";
export const BOOTCAMP_REGISTRATION_FAILURE = "BOOTCAMP_REGISTRATION_FAILURE";

export const BOOTCAMP_USER_SUCCESS = "BOOTCAMP_USER_SUCCESS";
export const BOOTCAMP_USER_FAILURE = "BOOTCAMP_USER_FAILURE";

export const BOOTCAMP_UPDATE_SUCCESS = "BOOTCAMP_UPDATE_SUCCESS";
export const BOOTCAMP_UPDATE_FAILURE = "BOOTCAMP_UPDATE_FAILURE";

export const BOOTCAMP_LIVE_PAYMENT_SUCCESS = "BOOTCAMP_LIVE_PAYMENT_SUCCESS";
export const BOOTCAMP_LIVE_PAYMENT_FAILURE = "BOOTCAMP_LIVE_PAYMENT_FAILURE";

export const BOOTCAMP_MANUAL_PAYMENT_SUCCESS =
  "BOOTCAMP_MANUAL_PAYMENT_SUCCESS";
export const BOOTCAMP_MANUAL_PAYMENT_FAILURE =
  "BOOTCAMP_MANUAL_PAYMENT_FAILURE";
