import React, { useState } from "react";
import { STICKER_DATA } from "../../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { applyUserSticker } from "../../../redux/actions/stickers_action";
import SectionTitle from "../../../common/shared/SectionTitle";
import AlertMessage from "../../../common/actions/AlertMessage";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import InputText from "../../../common/forms/InputText";
import SelectOption from "../../../common/forms/SelectOption";

const link = [
  { name: "Sticker List", link: "/home/sticker" },
  { name: "Sticker Application", link: "" },
];

const stickerQty = [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
];

function NewStickers() {
  const [loading, setLoading] = useState(false);
  const [qtyError, setQtyError] = useState("");

  const [formData, setFormData] = useState(STICKER_DATA);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { stickerError } = useSelector((state) => state.sticker);

  const updateFormValue = ({ updateType, value }) => {
    setFormData({ ...formData, [updateType]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.qty) {
      setQtyError("Please select sticker quantity.");
      return;
    }

    setLoading(true);

    const sendData = {
      plate_a: formData.plate_a,
      plate_b: formData.plate_b,
      plate_c: formData.plate_c,
      qty: parseInt(formData.qty),
    };

    dispatch(applyUserSticker(sendData, navigate)).finally(() => {
      setLoading(false);
      setQtyError("");
    });
  };
  return (
    <>
      <SectionTitle
        title="Sticker Application"
        description="Please provide your vehicle plate details in the boxes below"
        children={link}
      />

      <div className="my-8 max-w-[1200px] m-auto px-4">
        {stickerError && <AlertMessage type="error" message={stickerError} />}

        <Card>
          <form onSubmit={(e) => handleSubmit(e)}>
            <CardBody>
              <Typography color="blue-gray" variant="h5">
                Sticker Application Form
              </Typography>
              <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-4 my-4">
                <div className="mb-4 sm:mb-0">
                  <InputText
                    defaultValue={formData.plate_a}
                    updateType="plate_a"
                    labelTitle="Only Regional Code / Customized Code"
                    updateFormValue={updateFormValue}
                    placeholder="GT"
                    required={true}
                    maxLength={6}
                  />
                  {/* <Typography
                    variant="small"
                    color="gray"
                    className="mt-2 flex items-center gap-1 font-normal"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="-mt-px h-4 w-4"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Please leave the section blank for customised plates
                  </Typography> */}
                </div>

                <InputText
                  defaultValue={formData.plate_b}
                  updateType="plate_b"
                  labelTitle="Only Number"
                  updateFormValue={updateFormValue}
                  required={true}
                  placeholder="0000"
                  maxLength={4}
                />

                <InputText
                  defaultValue={formData.plate_c}
                  updateType="plate_c"
                  labelTitle="Only Year"
                  updateFormValue={updateFormValue}
                  required={true}
                  placeholder="24"
                  maxLength={2}
                />
              </div>
              <SelectOption
                labelTtile="Select Sticker Quantity"
                defaultValue={formData.qty.toString() || ""}
                updateFormValue={updateFormValue}
                updateType="qty"
                children={stickerQty}
                required={true}
              />
              {qtyError && <p className="text-red-500">{qtyError}</p>}

              <div className="mt-20 text-center">
                <Typography color="red" className="mb-8">
                  GBA vehicle stickers shall be ready within 14 days of approval
                </Typography>
                <Typography variant="small" color=" black">
                  By clicking to proceed below you affirm that the information
                  and data you have provided on this form are true; and that you
                  are not knowingly submitting information which is untrue,
                  misleading or false.
                </Typography>
              </div>
            </CardBody>
            <CardFooter className="flex justify-center">
              <Button type="submit" className="flex item-center gap-3">
                {loading && <Spinner color="white" />}
                Proceed
              </Button>
            </CardFooter>
          </form>
        </Card>
      </div>
    </>
  );
}

export default NewStickers;
