import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function BlackCard({ title, desc, link, icon, btnTitle }) {
  const navigate = useNavigate();
  return (
    <Card
      color="gray"
      className="w-full h-full flex justify-center items-center"
    >
      <CardBody className="flex flex-col pt-10 pb-6 justify-between w-full h-full items-center text-center">
        <div className="mx-auto mb-6 w-[4rem] h-[4rem]">{icon}</div>
        <Typography variant="h5" color="white">
          {title}
        </Typography>
        <Typography>{desc}</Typography>
        <Button
          onClick={() => navigate(`${link}`)}
          className="mt-10"
          color="white"
          fullWidth
        >
          {btnTitle}
        </Button>
      </CardBody>
    </Card>
  );
}

export default BlackCard;
