import createAsyncAction from "../../utils/asyncAction";

import * as api from "../api/conference/conference_api";
import * as types from "../constants/conference_constants";

const getConferenceTypes = {
  success: types.GET_CONFERENCE_SUCCESS,
  failure: types.GET_CONFERENCE_FAILURE,
};

const registerConferenceTypes = {
  success: types.REGISTER_CONFERENCE_SUCCESS,
  failure: types.REGISTER_CONFERENCE_FAILURE,
};

const paymentConferenceTypes = {
  success: types.CONFERENCE_PAYMENT_SUCCESS,
  failure: types.CONFERENCE_PAYMENT_FAILURE,
};

const manualConferenceTypes = {
  success: types.CONFERENCE_MANUAL_SUCCESS,
  failure: types.CONFERENCE_MANUAL_FAILURE,
};

const conferenceReceiptTypes = {
  success: types.CONFERENCE_RECEIPT_SUCCESS,
  failure: types.CONFERENCE_RECEIPT_FAILURE,
};

const conferenceNeedsTypes = {
  success: types.CONFERENCE_NEEDS_SUCCESS,
  failure: types.CONFERENCE_NEEDS_FAILURE,
};

// const conferenceAction =
//   (apiFxn, actionTypes) => (payload) => async (dispatch) => {
//     try {
//       const response = await apiFxn(payload);
//       const { error, data } = response;

//       if (error) {
//         dispatch({ type: actionTypes.failure, payload: error });
//       } else {
//         dispatch({ type: actionTypes.success, payload: data });
//         window.location.reload();
//       }
//     } catch (error) {
//       dispatch({ type: actionTypes.failure, payload: error.message });
//     }
//   };

export const getConference = createAsyncAction(
  api.getConference,
  getConferenceTypes
);

export const registerConference = createAsyncAction(
  api.registerConference,
  registerConferenceTypes
);

export const conference_payment = createAsyncAction(
  api.updateConference,
  paymentConferenceTypes
);

export const conferenceManual = createAsyncAction(
  api.manualConference,
  manualConferenceTypes
);

export const conferenceReceipt = createAsyncAction(
  api.conferenceReceipt,
  conferenceReceiptTypes
);

export const conferenceNeeds = createAsyncAction(
  api.conferenceNeeds,
  conferenceNeedsTypes
);

export const upoloadConferenceFace = (formdata) => async (dispatch) => {
  try {
    const response = await api.uploadFace(formdata);
    const { error, data } = response;

    if (error) {
      dispatch({ type: types.CONFERENCE_FACE_FAILURE, payload: error });
    } else {
      dispatch({ type: types.CONFERENCE_FACE_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch({
      type: types.CONFERENCE_FACE_FAILURE,
      payload: error.message,
    });
  }
};

// export const getConference = () => async (dispatch) => {
//   try {
//     const { error, data } = await api.getConference();

//     if (error) {
//       dispatch({ type: types.GET_CONFERENCE_FAILURE, payload: error });
//     }

//     dispatch({ type: types.GET_CONFERENCE_SUCCESS, payload: data });
//   } catch (error) {
//     dispatch({ type: types.GET_CONFERENCE_FAILURE, payload: error.message });
//   }
// };

// export const registerConference = (values) => async (dispatch) => {
//   try {
//     const response = await api.registerConference(values);
//     const { error, data } = response;

//     if (error) {
//       dispatch({ type: types.REGISTER_CONFERENCE_FAILURE, payload: error });
//     }

//     dispatch({
//       type: types.REGISTER_CONFERENCE_SUCCESS,
//       payload: data,
//     });
//   } catch (error) {
//     dispatch({ type: types.REGISTER_CONFERENCE_FAILURE, payload: error });
//   }
// };

// export const conference_payment = (value) => async (dispatch) => {
//   try {
//     const response = await api.updateConference(value);
//     const { error, data } = response;

//     if (error) {
//       dispatch({ type: types.CONFERENCE_PAYMENT_FAILURE, payload: error });
//     }

//     dispatch({ type: types.CONFERENCE_PAYMENT_SUCCESS, payload: data });
//   } catch (error) {
//     dispatch({ type: types.CONFERENCE_PAYMENT_FAILURE, payload: error });
//   }
// };
