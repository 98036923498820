import { Button } from "@material-tailwind/react";
import React from "react";

function HotelButton({ link }) {
  return (
    <Button color="brown" onClick={() => window.open(link, "_blank")}>
      Available Hotel List
    </Button>
  );
}

export default HotelButton;
