import { Chip } from "@material-tailwind/react";
import React from "react";

function ConferenceStatusTag({ data }) {
  return (
    <div className="flex mb-2">
      {data.exempt && data.receipt?.amount === 0 ? (
        <Chip
          color="indigo"
          variant="ghost"
          value="EXEMPTED. REGISTERED FOR CONFERENCE"
        />
      ) : data.receipt ? (
        <Chip color="green" variant="ghost" value="PAID" />
      ) : data.receiptStatus === true ? (
        <Chip
          color="light-blue"
          variant="ghost"
          value={`REGISTERED (${data.conf_type}). NOT PAID`}
        />
      ) : (
        <Chip color="amber" variant="ghost" value="NOT REGISTERED" />
      )}
    </div>
  );
}

export default ConferenceStatusTag;
