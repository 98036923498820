import { Button } from "@material-tailwind/react";
import React from "react";

import { useNavigate } from "react-router-dom";

function DuesPayButton({ data }) {
  const navigate = useNavigate();

  const formData = {
    data: data,
  };

  const handleDuesPage = () => {
    navigate("/home/dues/payment", { state: { formData } });
  };

  return (
    <>
      <Button
        onClick={() => handleDuesPage()}
        color="cyan"
        disabled={data.isDisable ? !data.exempt : false}
        fullWidth
      >
        Make Payment / View Receipts
      </Button>
    </>
  );
}

export default DuesPayButton;
