import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as api from "../../../redux/api/conference/conference_api";
import * as types from "../../../redux/constants/conference_constants";
import { Button, Spinner } from "@material-tailwind/react";

function ConferencePaymentButton({ amount, year, id }) {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleLive = async (e) => {
    e.preventDefault();

    setLoading(true);

    const formData = {
      amount,
      year,
      id,
    };

    try {
      const response = await api.updateConference(formData);
      const { error, data } = response;

      if (error) {
        setLoading(false);
        dispatch({ type: types.CONFERENCE_PAYMENT_FAILURE, payload: error });
      } else {
        window.location.href = data.receipt.url;
      }
    } catch (error) {
      window.location.reload();
      console.log(error);
    }
  };

  return (
    <Button color="pink" onClick={(e) => handleLive(e)}>
      {loading ? <Spinner color="white" /> : "E-Payment"}
    </Button>
  );
}

export default ConferencePaymentButton;
