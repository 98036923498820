import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserLicense } from "../../redux/actions/license_action";
import SectionTitle from "../../common/shared/SectionTitle";
import AlertMessage from "../../common/actions/AlertMessage";
import Loader from "../../common/loader/Loader";
import NoContent from "../../common/shared/NoContent";
import LicenseList from "./components/LicenseList";
import LicenseConditions from "./common/LicenseConditions";

const link = [{ name: "Solicitors License", link: "" }];

function License() {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const { data, licenseError } = useSelector((state) => state.license);

  useEffect(() => {
    dispatch(getUserLicense()).finally(() => {
      setLoading(false);
    });
  }, [dispatch]);

  const licenseData = data?.license || [];

  return (
    <>
      <SectionTitle
        title="Solicitors License"
        description="Note that you have to be in Good Standing, and should have completed pupillage in order to apply for Solicitors Licence."
        children={link}
        requirement
        details={<LicenseConditions data={licenseData[0]} />}
        link={"/home"}
      />
      <div className="my-8 max-w-[1200px] m-auto px-4">
        {licenseError && <AlertMessage type="error" message={licenseError} />}

        {loading ? (
          <Loader />
        ) : data?.license.length === 0 ? (
          <NoContent message="No License Yet." />
        ) : (
          <LicenseList data={data?.license} />
        )}
      </div>
    </>
  );
}

export default License;
