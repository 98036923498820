export const GET_CONFERENCE_SUCCESS = "GET_CONFERENCE_SUCCESS";
export const GET_CONFERENCE_FAILURE = "GET_CONFERENCE_FAILURE";

export const REGISTER_CONFERENCE_SUCCESS = "REGISTER_CONFERENCE_SUCCESS";
export const REGISTER_CONFERENCE_FAILURE = "REGISTER_CONFERENCE_FAILURE";

export const CONFERENCE_PAYMENT_SUCCESS = "CONFERENCE_PAYMENT_SUCCESS";
export const CONFERENCE_PAYMENT_FAILURE = "CONFERENCE_PAYMENT_FAILURE";

export const CONFERENCE_MANUAL_SUCCESS = "CONFERENCE_MANUAL_SUCCESS";
export const CONFERENCE_MANUAL_FAILURE = "CONFERENCE_MANUAL_FAILURE";

export const CONFERENCE_RECEIPT_SUCCESS = "CONFERENCE_RECEIPT_SUCCESS";
export const CONFERENCE_RECEIPT_FAILURE = "CONFERENCE_RECEIPT_FAILURE";

export const CONFERENCE_NEEDS_SUCCESS = "CONFERENCE_NEEDS_SUCCESS";
export const CONFERENCE_NEEDS_FAILURE = "CONFERENCE_NEEDS_FAILURE";

export const CONFERENCE_FACE_SUCCESS = "CONFERENCE_FACE_SUCCESS";
export const CONFERENCE_FACE_FAILURE = "CONFERENCE_FACE_FAILURE";
