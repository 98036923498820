import createAsyncAction from "../../utils/asyncAction";
import * as types from "../constants/main_constants";
import * as api from "../api/main_api";

const getUserNameTypes = {
  success: types.GET_USER_NAME_SUCCESS,
  failure: types.GET_USER_NAME_FAILURE,
};

const updateSettingsTypes = {
  success: types.UPDATE_SETTING_SUCCESS,
  failure: types.UPDATE_SETTING_FAILURE,
};

export const getUserName = createAsyncAction(
  api.getUserNameApi,
  getUserNameTypes
);

export const updateSettings = createAsyncAction(
  api.updateSettingsApi,
  updateSettingsTypes
);
