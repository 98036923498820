import * as types from "../constants/stickers_constants";

const initialState = {
  data: null,
  stickerError: null,
  docs: null,
  files: null,
  message: null,
  stick: null,
};

const stickerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_USER_STIKER_SUCCESS:
    case types.SINGLE_STICKER_SUCCESS:
      return {
        ...state,
        data: payload ? payload.stickers : null,
        stickerError: null,
      };

    case types.UPLOAD_REG_STICKER_SUCCESS:
    case types.UPLOAD_SUPPORT_STICKER_SUCCESS:
      return {
        ...state,
        docs: payload ? payload.files : null,
        stickerError: null,
      };

    case types.STICKER_PAYMENT_SUCCESS:
      return {
        ...state,
        stick: payload ? payload.sticker : null,
        stickerError: null,
      };

    case types.APPLY_STICKER_SUCCESS:
      return {
        ...state,
        data: payload ? payload.sticker : null,
        stickerError: null,
      };

    case types.UPDATE_STICKER_SUCCESS:
      return {
        ...state,
        message: payload ? payload : null,
        stickerError: null,
      };

    case types.GET_USER_STIKER_FAILURE:
    case types.APPLY_STICKER_FAILURE:
    case types.STICKER_PAYMENT_FAILURE:
    case types.UPLOAD_REG_STICKER_FAILURE:
    case types.UPLOAD_SUPPORT_STICKER_FAILURE:
    case types.SINGLE_STICKER_FAILURE:
      return { ...state, stickerError: payload ? payload : null };

    default:
      return state;
  }
};

export default stickerReducer;
