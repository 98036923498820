import React from "react";
import SectionTitle from "../../common/shared/SectionTitle";
import { useSelector } from "react-redux";
import AlertMessage from "../../common/actions/AlertMessage";
import UpdateForm from "./components/UpdateForm";
import ChangeProfileImg from "./components/ChangeProfileImg";
import {
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import FacialImg from "./components/FacialImg";

const link = [{ name: "Profile Update", link: "" }];

function UpdateProfile() {
  const { updateError, successMsg } = useSelector((state) => state.auth);

  const pages = [
    {
      label: "User Details",
      value: "user_details",
      page: <UpdateForm />,
    },
    {
      label: "Facial Image",
      value: "facial",
      page: <FacialImg />,
    },
    // {
    //   label: "Profile Image",
    //   value: "change_profile_image",
    //   page: <ChangeProfileImg />,
    // },
  ];

  return (
    <>
      <SectionTitle title="Update Profile" description="" children={link} />;
      <div className="my-8 max-w-[1200px] m-auto px-4">
        {updateError && <AlertMessage type="error" message={updateError} />}
        {successMsg && <AlertMessage type="success" message={successMsg} />}

        <Tabs value="user_details">
          <TabsHeader>
            {pages.map(({ label, value }) => (
              <Tab key={value} value={value}>
                {label}
              </Tab>
            ))}
          </TabsHeader>
          <TabsBody>
            {pages.map(({ value, page }) => (
              <TabPanel key={value} value={value}>
                {page}
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
      </div>
    </>
  );
}

export default UpdateProfile;
