import { Typography } from "@material-tailwind/react";
import { Radio } from "antd";
import React, { useState } from "react";

function SettingsRadio({
  title,
  disable = false,
  defaultValue,
  settingType,
  field,
  value,
  onChange,
}) {
  // const [value, setValue] = useState(defaultValue);

  const handleChange = (e) => {
    onChange(settingType, field, e.target.value);
  };

  // const handleChange = (val) => {
  //   console.log("Hi, My name is", val);
  //   setValue(val.target.value);
  //   localStorage.setItem(item, JSON.stringify(val.target.value));
  //   updateFormValue({ updateType, value: val.target.value });
  // };

  return (
    <div className="flex items-center justify-between my-2">
      <Typography variant="paragraph">{title}</Typography>

      <Radio.Group value={value} onChange={handleChange}>
        <Radio value={true} disabled={disable}>
          Show
        </Radio>
        <Radio value={false} disabled={disable}>
          Hide
        </Radio>
      </Radio.Group>

      {/* <div className="flex gap-4"> */}

      {/* <Radio
          value="true"
          name="true"
          color="success"
          checked={data === true}
          disabled={disable}
          onChange={(e) => handleChange(e, true)}
        />
        <Radio
          value="false"
          name="false"
          color="error"
          checked={data === false}
          disabled={disable}
          onChange={(e) => handleChange(e, false)}
        /> */}
      {/* </div> */}
    </div>
  );
}

export default SettingsRadio;
