import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as api from "../../../redux/api/license/license_api";
import * as types from "../../../redux/constants/license_constants";
import { licenseManual } from "../../../redux/actions/license_action";
import {
  Button,
  Dialog,
  DialogFooter,
  DialogHeader,
  Spinner,
  Typography,
} from "@material-tailwind/react";

function LicensePayment({ data }) {
  const [liveLoading, setLiveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const userData = JSON.parse(localStorage.getItem("profile"));

  const dispatch = useDispatch();

  const handleManual = (e) => {
    e.preventDefault();
    const formData = {
      amount: data.amount,
      year: data.year,
      id: data.id,
    };

    setLoading(true);
    dispatch(licenseManual(formData)).finally(() => {
      setLoading(false);
      setOpen(true);
    });
  };

  const handleLive = async (e) => {
    e.preventDefault();
    const sendData = {
      amount: data.amount,
      year: data.year,
      id: data.id,
    };

    setLiveLoading(true);

    try {
      const response = await api.licensePayment(sendData);
      const { error, data } = response;

      if (error) {
        setLiveLoading(false);
        dispatch({ type: types.LICENSE_PAYMENT_FAILURE, payload: error });
      } else {
        const firstItem = data.license[0];

        if (firstItem && firstItem.receipts[0].url) {
          window.location.href = firstItem.receipts[0].url;
        }
      }
    } catch (error) {
      window.location.reload();
      console.log(error);
    }
  };

  const handleOpen = () => setOpen(!open);

  return (
    <div className="grid grid-cols-1 sx:grid-cols-1 md:grid-cols-2 gap-4 my-4">
      <Button
        disabled={
          !data.standing ||
          !data.pupilStatus ||
          data?.receipt ||
          (!data.manualGreen &&
            !data.previousReceipt &&
            data.length !== 1 &&
            !data.passManual) ||
          (!data.isRegion && parseInt(data.year) >= 2024)
        }
        // disabled={
        //   !userData?.user?.standing ||
        //   !data.pupilStatus ||
        //   (!data.previousReceipt &&
        //     !data.previousManual &&
        //     !data.manualGreen) ||
        //   (!data.isRegion && parseInt(data.year) >= 2024)
        // }
        color="pink"
        onClick={(e) => handleLive(e)}
      >
        {liveLoading ? <Spinner color="white" /> : "E-Payment"}
      </Button>

      <Button
        disabled={
          !data.standing ||
          !data.pupilStatus ||
          data?.receipt ||
          (!data.manualGreen &&
            !data.previousReceipt &&
            data.length !== 1 &&
            !data.passManual) ||
          (!data.isRegion && parseInt(data.year) >= 2024)
        }
        color="amber"
        onClick={(e) => handleManual(e)}
      >
        {loading ? <Spinner color="white" /> : "Manual Pay"}
      </Button>

      {open && (
        <Dialog open={open} handleOpen={handleOpen}>
          <DialogHeader>
            <Typography variant="h5" color="blue-gray">
              Manual Payment Successfully Added
            </Typography>
          </DialogHeader>
          <DialogFooter>
            <Button variant="text" color="blue-gray" onClick={handleOpen}>
              Close
            </Button>
          </DialogFooter>
        </Dialog>
      )}
    </div>
  );
}

export default LicensePayment;
