import { Button, Card, CardBody, CardFooter } from "@material-tailwind/react";
import React, { useState } from "react";
import FileUpload from "../../../common/forms/FileUpload";
import * as api from "../../../redux/api/auth/auth_api";
import AlertMessage from "../../../common/actions/AlertMessage";
import { useNavigate } from "react-router-dom";

function ChangeProfileImg() {
  const [userData, setUserData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/home");
  };

  const handleUpload = async (file) => {
    try {
      const response = await api.uploadImage(file);
      const { error, data } = response;

      if (error) {
        setErrorMessage(error.message);
      } else {
        setUserData(data.user);
        const user = JSON.parse(localStorage.getItem("profile")).user;
        user.avatar = data.user.avatar;
        localStorage.setItem("profile", JSON.stringify({ user }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card>
      <CardBody className="pt-11 pb-26">
        {errorMessage && <AlertMessage type="error" message={errorMessage} />}
        <FileUpload
          label="Set Profile Image"
          onUpload={(file) => handleUpload(file)}
        />

        <div className="mt-16 text-center">
          {userData && (
            <img
              src={`https://storage-gba.s3.eu-west-1.amazonaws.com/profile/${userData?.avatar}`}
              alt=""
              className="mx-auto block"
            />
          )}
        </div>
      </CardBody>
      <CardFooter className="text-center">
        <Button disabled={!userData} onClick={() => handleNavigate()}>
          Done
        </Button>
      </CardFooter>
    </Card>
  );
}

export default ChangeProfileImg;
