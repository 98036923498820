import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as api from "../../../redux/api/bootcamp/bootcamp_api";
import * as types from "../../../redux/constants/bootcamp_constants";
import { Button, Spinner } from "@material-tailwind/react";

function BootcampEPaymentButton() {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleLive = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await api.userBootcampEPayment();
      const { error, data } = response;

      if (error) {
        setLoading(false);
        dispatch({ type: types.BOOTCAMP_LIVE_PAYMENT_SUCCESS, payload: error });
      } else {
        window.location.href = data.receipt.url;
      }
    } catch (error) {
      window.location.reload();
      console.log(error);
    }
  };
  return (
    <Button color="pink" onClick={(e) => handleLive(e)} disabled={loading}>
      {loading ? <Spinner color="white" /> : "E-Payment"}
    </Button>
  );
}

export default BootcampEPaymentButton;
