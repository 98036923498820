import {
  BOOTCAMP_LIVE_PAYMENT,
  BOOTCAMP_MANUAL_PAYMENT,
  BOOTCAMP_REGISTRATION,
  BOOTCAMP_UPDATE,
  BOOTCAMP_USER,
} from "../../constants/api_constants";
import { handleApi } from "../utils";

export const userBootcampRegistration = async (values) => {
  return handleApi("post", BOOTCAMP_REGISTRATION, values);
};

export const userBootcamp = async () => {
  return handleApi("get", BOOTCAMP_USER);
};

export const userBootcampUpdate = async (values) => {
  return handleApi("put", `${BOOTCAMP_UPDATE}/${values.id}`, values);
};

export const userBootcampEPayment = async () => {
  return handleApi("get", BOOTCAMP_LIVE_PAYMENT);
};

export const userBootcampManualPayment = async () => {
  return handleApi("get", BOOTCAMP_MANUAL_PAYMENT);
};
