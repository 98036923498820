import {
  Button,
  Dialog,
  DialogFooter,
  DialogHeader,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ManualPaymentButton({
  amount,
  year,
  id,
  post_type,
  check,
  apiFxn,
  name = null,
}) {
  const [manualLoading, setManualLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const showToastMessage = () => {
    toast.success("Manual Payment added successfully", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleOpen = () => setOpen(!open);

  const handleManual = () => {
    setManualLoading(true);
    const formData = {
      amount,
      year,
      id,
      post_type,
      name,
    };

    dispatch(apiFxn(formData)).finally(() => {
      setManualLoading(false);
      setOpen(true);
    });
  };

  return (
    <>
      <Button
        onClick={() => handleManual()}
        size="md"
        color="amber"
        disabled={check}
      >
        {manualLoading ? <Spinner /> : "Manual Pay"}
      </Button>

      {open && (
        <Dialog open={open} handleOpen={handleOpen}>
          <DialogHeader>
            <Typography variant="h5" color="blue-gray">
              Manual Payment Successfully Added
            </Typography>
          </DialogHeader>
          <DialogFooter>
            <Button variant="text" color="blue-gray" onClick={handleOpen}>
              Close
            </Button>
          </DialogFooter>
        </Dialog>
      )}
    </>
  );
}

export default ManualPaymentButton;
