import { Button } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function LicenseMakePaymentButton({ data, disable = false }) {
  const navigate = useNavigate();

  const formData = { data };

  const handleNavigate = () => {
    navigate("/home/license/invoice", { state: { formData } });
  };

  return (
    <Button
      fullWidth
      color="cyan"
      disabled={disable}
      onClick={() => handleNavigate()}
    >
      Make Payment
    </Button>
  );
}

export default LicenseMakePaymentButton;
