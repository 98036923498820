import createAsyncAction from "../../utils/asyncAction";
import * as api from "../api/stickers/stickers_api";
import * as types from "../constants/stickers_constants";

const getUserStickersTypes = {
  success: types.GET_USER_STIKER_SUCCESS,
  failure: types.GET_USER_STIKER_FAILURE,
};

const singleStickersTypes = {
  success: types.SINGLE_STICKER_SUCCESS,
  failure: types.SINGLE_STICKER_FAILURE,
};

const updateUserStickersTypes = {
  success: types.UPDATE_STICKER_SUCCESS,
  failure: types.UPDATE_STICKER_FAILURE,
};

const uploadFileAction =
  (fileType, id, formData, successType, failureType) => async (dispatch) => {
    try {
      const response = await api[`upload${fileType}`](id, formData);
      const { error, data } = response;

      if (error) {
        dispatch({ type: failureType, payload: error });
      } else {
        dispatch({ type: successType, payload: data });
      }
    } catch (error) {
      dispatch({
        type: failureType,
        payload: error,
      });
    }
  };

export const getUserStickers = createAsyncAction(
  api.getUserStickers,
  getUserStickersTypes
);

export const updateUserSticker = createAsyncAction(
  api.updateUserSticker,
  updateUserStickersTypes
);

export const singleSticker = createAsyncAction(
  api.singleSticker,
  singleStickersTypes
);

export const uploadRegFile = (id, formData) =>
  uploadFileAction(
    "RegDoc",
    id,
    formData,
    types.UPLOAD_REG_STICKER_SUCCESS,
    types.UPLOAD_REG_STICKER_FAILURE
  );

export const uploadSupportFile = (id, formData) =>
  uploadFileAction(
    "SupportDoc",
    id,
    formData,
    types.UPLOAD_REG_STICKER_SUCCESS,
    types.UPLOAD_REG_STICKER_FAILURE
  );

export const applyUserSticker = (formData, navigate) => async (dispatch) => {
  try {
    const response = await api.applyUserSticker(formData);
    const { error, data } = response;

    if (error) {
      dispatch({ type: types.APPLY_STICKER_FAILURE, payload: error });
    } else {
      const { sticker } = data;
      dispatch({ type: types.APPLY_STICKER_SUCCESS, payload: sticker });
      navigate(`/home/stickers/upload/${sticker.id}`);
    }
  } catch (error) {
    dispatch({
      type: types.APPLY_STICKER_FAILURE,
      payload: "No Connection",
    });
  }
};
